.lmenu {
    position: absolute;
    top: 10px;
    right: 20px;

    font-size: 32px;
    margin-left: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
  }

  .menubutton {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    font-size: 24px;
}

.menubutton:hover {
  color: rgba(255, 255, 255, 0.644);
}


  .ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 9999;
  }