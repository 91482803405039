.prevpages {
    padding: 25px;
    
}

.previewSelClient {
    position: absolute;
    width: 350px;
    left: 150px;
    top: 0px;
    padding: 25px;
}

.container-landscape {
    position: relative;
    background-color: white;
    color: black;
    padding: 25px;
    width: 800px;
    height: 545px;
}
.row { 
    display: table-row;
}
.cell { 
    display: table-cell;
    width: 100px;
}
.caltimpo{
    position: absolute;
    top: 45px; 
    right: 45px;
    font-weight: bold;
}

.cattimeall{
    position: absolute;
    top: 5px;
    left: 15px;
    font-weight: bold;
    font-size: 20px;

    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.caltitle{
    position: absolute;
    top: 25px;
    right: 45px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.firmwareapp{
    position: absolute;
    bottom: 25px;
    right: 45px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;
}

.cattb01{
    position: absolute;
    top: 90px;
    left: 25px;
    font-size: 10px;
    width: 13%;
    font-weight: bold;
    background-color: rgb(250, 250, 250);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.cattb02{
    position: absolute;
    top: 90px;
    left: 17%;
    font-size: 10px;
    width: 6%;
    font-weight: 900;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.cattb03{
    position: absolute;
    top: 90px;
    left: 24.402%;
    font-size: 10px;
    width: 10%;
    font-weight: bold;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.cattb04{
    position: absolute;
    top: 90px;
    left: 38%;
    font-size: 10px;
    width: 10%;
    font-weight: bold;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.cattb05{
    position: absolute;
    top: 90px;
    left: 49.3%;
    font-size: 10px;
    width: 10%;
    font-weight: bold;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.cattb06{
    position: absolute;
    top: 90px;
    left: 63%;
    font-size: 10px;
    width: 10%;
    font-weight: bold;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.cattb07{
    position: absolute;
    top: 90px;
    left: 74.4%;
    font-size: 10px;
    width: 17%;
    font-weight: bold;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
}

.catempresaname{
    position: absolute;
    top: 30px;
    left: 150px;
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.partlogo {
    position: absolute;
    display: inline-block;
    margin: 13px 0px 0px 0px;
    padding: 3px;
    top: 5px;
    left: 50px;
    width: 75px;
    height: auto;
}

.FirmaDigital {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 100px;
    height: 100px;
}

.CalendarBox{
    width: 780px;
    height: 450px;
    border: solid 0px red;
    position: absolute;
    top: 125px;
    font-family: "Lucida Console", "Courier New", monospace;
}

.DayBox{
    border: 1px solid black;
    width: 17.5%;
    height: 75px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
}

.DayBoxFREE{
    border: 0px solid black;
    width: 17.75%;
    height: 75px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
}

.SpaceCap{
    background-color: rgba(0, 0, 0, 0);
    border: solid 0px red;
    width: 5%;
    height: 20px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 14px;
}

.DayCap {
    border: solid 0px red;
    width: 17.50%;
    height: 20px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 14px;
}

.WeekCap {
    border: solid 0px red;
    width: 5%;
    height: 75px;
    float: left;
    margin: 2px;
    font-size: 14px;
    text-align: center;
}

.DateDayBox {
    font-size: 12px;
    text-align: center;
    position: absolute;
    color: black;
    top: 0%;
    left: 0%;
    margin: 1px;
    padding: 3px;
    width: 30%;
    pointer-events: none;
    border: solid 0px rgb(0, 0, 0);
    background-color: rgb(238, 238, 238);
}

.CapLugar{
    font-size: 10px;
    text-align: center;
    position: absolute;
    color: black;
    bottom: 0px;
    left: 0%;
    margin: 1px;
    padding: 3px;
    width: 92%;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.CapHoras{
    font-size: 10px;
    position: absolute;
    color: black;
    top: 0px;
    left: 20%;
    margin: 1px;
    padding: 3px;
    width: 92%;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.container-portrait{
    position: relative;
    background-color: white;
    color: black;
    padding: 25px;
    width: 545px;
    height: 790px;
}


.catps01{
    position: absolute;
    top: 90px;
    left: 25px;
    font-size: 10px;
    width: 20%;
    background-color: rgb(250, 250, 250);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps02{
    position: absolute;
    top: 90px;
    left: 26.1%;
    font-size: 10px;
    min-width: 7%;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps03{
    position: absolute;
    top: 90px;
    left: 35.0%;
    font-size: 10px;
    width: 15%;
    font-weight: bold;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps04{
    position: absolute;
    top: 90px;
    left: 55%;
    font-size: 10px;
    width: 11%;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps05{
    position: absolute;
    top: 90px;
    left: 67.9%;
    font-size: 10px;
    width: 25%;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps06{
    position: absolute;
    top: 111px;
    left: 25px;
    font-size: 10px;
    width: 20%;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps07{
    position: absolute;
    top: 111px;
    left:  26.1%;
    font-size: 10px;
    width: 24%;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catps08{
    position: absolute;
    top: 111px;
    left: 55%;
    font-size: 10px;
    width: 11%;
    background-color: rgb(238, 238, 238);
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;
}

.catps09{
    position: absolute;
    top: 111px;
    left: 67.9%;
    font-size: 10px;
    width: 25%;
    border: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
    font-family: "Lucida Console", "Courier New", monospace;

}

.table001 {
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    top: 140px;
    left: 25px;
    width: 90.5%;
    height: 15px;
    position: absolute;
    font-family: "Lucida Console", "Courier New", monospace;
    display: inline;
}

.table002 {
    border: 1px solid black;
    top: 155.5px;
    left: 25px;
    width: 90.5%;
    height: 25px;
    position: absolute;
    font-family: "Lucida Console", "Courier New", monospace;

}

.tableC1 {
    border: 1px solid black;
    bottom: 45px;
    left: 25px;
    width: 45.5%;
    height: 120px;
    position: absolute;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catpsTC01{
    position: absolute;
    top: -1px;
    left: -1px;
    font-size: 8px;
    height: 15px;
    width: 80%;
    padding: 5px;
    text-transform: uppercase;
}

.catpsTC02{
    position: absolute;
    top: 15px;
    left: 0px;
    font-size: 20px;
    height: 15px;
    width: 5%;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catpsTC03{
    position: absolute;
    top: -1px;
    right: 5px;
    font-size: 8px;
    height: 15px;
    width: 10%;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catpsTC04{
    position: absolute;
    bottom: 5px;
    right: 0px;
    font-size: 16px;
    height: 15px;
    width: 60%;
    padding: 5px;
    text-align: right;
    text-transform: uppercase;
}

.tableC2 {
    border: 1px solid black;
    bottom: 45px;
    right: 25px;
    width: 45.5%;
    height: 120px;
    position: absolute;
    font-family: "Lucida Console", "Courier New", monospace;

}



.table003 {
    border: 1px solid black;
    top: 185px;
    left: 25px;
    width: 90.5%;
    height: 175px;
    position: absolute;
    font-family: "Lucida Console", "Courier New", monospace;

}

.catpsTI01{
    position: absolute;
    top: 0px;
    Left: 0px;
    font-size: 8px;
    height: 15px;
    width: 10%;
    padding: 5px;
    text-transform: uppercase;
}

.catpsTI02{
    position: relative;
    top: 18px;
    Left: 25px;
    font-size: 12px;
    height: auto;
    width: 90%;
    text-transform: uppercase;
}


.table004 {
    border-top: 0px solid black;
    top: 364px;
    left: 25px;
    width: 90.5%;
    height: 175px;
    position: absolute;
    font-family: "Lucida Console", "Courier New", monospace;
}

.catpsTN01{
    position: absolute;
    top: 0px;
    Left: 0px;
    font-size: 8px;
    height: 15px;
    width: 10%;
    padding: 5px;
    text-transform: uppercase;
}

.catpsTN02{
    position: relative;
    top: 18px;
    Left: 25px;
    font-size: 12px;
    height: auto;
    width: 90%;
    text-transform: uppercase;
}

.catps10{
    position: absolute;
    top: -1px;
    left: -1px;
    font-size: 10px;
    height: 15px;
    width: 40%;
    border: solid 0px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps11{
    position: absolute;
    top: -1px;
    left: 41.8%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps12{
    position: absolute;
    top: -1px;
    left: 48.75%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps13{
    position: absolute;
    top: -1px;
    left: 55.8%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps14{
    position: absolute;
    top: -1px;
    left: 62.8%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps15{
    position: absolute;
    top: -1px;
    left: 69.8%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps16{
    position: absolute;
    top: -1px;
    left: 76.8%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps17{
    position: absolute;
    top: -1px;
    left: 83.8%;
    font-size: 10px;
    height: 15px;
    width: 5%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

.catps18{
    position: absolute;
    top: -1px;
    left: 90.8%;
    font-size: 10px;
    height: 15px;
    width: 7%;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}

/* CAT SEMANA */

.catps10s{
    position: absolute;
    top: -1px;
    left: -1px;
    font-size: 10px;
    height: 8px;
    width: 40%;
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps11s{
    position: absolute;
    top: -1px;
    left: 41.8%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps12s{
    position: absolute;
    top: -1px;
    left: 48.75%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps13s{
    position: absolute;
    top: -1px;
    left: 55.8%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps14s{
    position: absolute;
    top: -1px;
    left: 62.8%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps15s{
    position: absolute;
    top: -1px;
    left: 69.8%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps16s{
    position: absolute;
    top: -1px;
    left: 76.8%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps17s{
    position: absolute;
    top: -1px;
    left: 83.8%;
    font-size: 10px;
    height: 9px;
    width: 31px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}

.catps18s{
    position: relative;
    top: -1px;
    left: 90.8%;
    font-size: 10px;
    height: 9px;
    width: 42px;
    border-left: solid 1px rgb(0, 0, 0);
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
}