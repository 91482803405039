.saavamini {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    z-index: 0;
    padding: 0px;
    /* mix-blend-mode:multiply; */
}

.list_cards {
    margin: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    gap: 20px;
}

.listitm {
    height: 200px;
}
 
.header {
    background-color: #327a81;
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
  }
  
  .table-users {
    border-radius: 10px;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
    max-width: calc(100% - 2em);
    margin: 1em auto;
    overflow: hidden;
    width: 800px;
  }
  
  table {
    width: 100%;
  }
  table td, table th {
    color: #fcb322;
    padding: 3px;
  }
  table td {
    text-align: center;
    vertical-align: middle;
  }
  table td:last-child {
    font-size: 0.95em;
    line-height: 1.4;
    text-align: left;
  }
  table th {
    font-weight: 300;
  }
  table tr:nth-child(2n) {
    background-color: #292929;
  }
  table tr:nth-child(2n+1) {
    background-color: #191919;
  }
  

  .card__background .saava {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(100%) blur(5px);
}



.label-admin {
    position: absolute;
    top: -5px;
    right: -5px;
    display: inline-block;
    padding: 0 0.5em;
    font-size: 1.75rem;
    line-height: 2.0em;
    color: #51ff00;
    border-radius: 5px;
  }

  .label-admin2 {
    position: absolute;
    top: 35px;
    right: 5px;
    display: inline-block;
    padding: 0 0.5em;
    font-size: 0.75rem;
    line-height: 2.0em;
    font-weight: bold;
    color: #2b2b2b;
    background-color: #ff9100;
    border-radius: 5px;
  }

  .label-role {
    position: absolute;
    top: 5px;
    right: 5px;
    display: inline-block;
    padding: 0 0.5em;
    font-size: 0.75rem;
    line-height: 2.0em;
    color: #f5f5f5;
    background-color: #ff910034;
    border-radius: 5px;
  }

  .label-id {
    position: absolute;
    top: 5px;
    left: 5px;
    display: inline-block;
    padding: 0 0.5em;
    font-size: 0.75rem;
    line-height: 2.0em;
    color: #ff9100;
    background-color: #2b2b2be0;
    border-radius: 5px;
  }

  .label-new {
    position: absolute;
    top: 35px;
    left: 5px;
    display: inline-block;
    padding: 0 0.5em;
    font-size: 0.75rem;
    line-height: 2.0em;
    color: #000000;
    background-color: #21f556e0;
    border-radius: 5px;
  }

.label {
    position: absolute;
    top: 5px;
    left: 5px;
    display: inline-block;
    padding: 0 0.75em;
    border-radius: 5px 2px 2px 0;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 2.35em;
    font-weight: bold;
    color: #6b4e00;
    background-color: #ffc938;
    z-index: 11;
    -moz-border-radius: 5px 2px 2px 0;
    -webkit-border-radius: 5px;
    border-radius: 5px 2px 2px 0;
    -moz-box-shadow: 0px 2px 0 rgba(31, 41, 51, 0.1);
    -webkit-box-shadow: 0px 2px 0 rgba(31, 41, 51, 0.1);
    box-shadow: 0px 2px 0 rgba(31, 41, 51, 0.1);
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
  }
  .label:before {
    content: " ";
    display: block;
    position: absolute;
    width: 5px;
    height: 4px;
    left: 0;
    bottom: -4px;
    background-color: #ffc938;
  }
  .label:after {
    content: " ";
    display: block;
    position: absolute;
    width: 5px;
    height: 6px;
    bottom: -6px;
    left: 0;
    background-color: #9e7300;
    -moz-border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px 0 0 5px;
  }

  /* XXXXXXXXXXXXX CARDS XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX*/

.master {
    line-height: 16px;
    display: flex;
    
  }
  .container {
    flex-wrap: wrap;
    margin: 0 auto;
    min-width: 700px;
    display: flex;
    /* justify-content: space-between; */
    justify-content: stretch;
  
  }
  .card {
    position: relative;
    text-decoration: none;
    border-radius: 5px;
    overflow: hidden;
    height: 200px;
    border: 0px solid rgb(255, 174, 0);
    /* background-color: #ffa41c; */
    cursor: pointer;
  }
  .card__background,
  .card__shadow,
  .card__info,
  .card__follow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .card.hover .card__background,
  .card:hover .card__background {
    filter: blur(2px);
    transform: translateY(-10px);
  }
  .card__background {
    background-size: cover;
    background-position: center;
    opacity: 0.95;
    transform: translateY(0);
    transition: transform 0.2s ease-in-out, filter 0.2s ease;
  }
  .card.hover .card__shadow,
  .card:hover .card__shadow {
    opacity: 0.95;
  }
  .card__shadow {
    background-image: linear-gradient(180deg, rgba(0,0,0,0.3) 0, #000 100%);
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
  }
  .card.hover .card__info,
  .card:hover .card__info {
    transform: translateY(-37px);
  }
  .card__info {
    top: auto;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 15px 10px;
    transform: translateY(10px);
    transition: transform 0.2s ease-in-out;
  }
  .card__name {
    text-transform: capitalize;
    font-size: 28px;
    line-height: 30px;
    font-weight: 300;
    margin-right: 10%;
    color: rgba(255,255,255,0.95);
    text-shadow: 0px 0px 10px rgba(0,0,0,0.95);
  }
  .card__name:after {
    content: '';
    display: block;
    width: 20%;
    height: 2px;
    background-color: #f2d423;
    margin: 5px 0 3px;
  }
  .card__about,
  .card__description {
    color: rgba(255,255,255,0.8);
    margin-top: 8px;
  }
  .card__about {
    align-self: flex-start;
    line-height: 14px;
  }
  .card__about .country {
    text-transform: capitalize;
  }
  .card.hover .card__description,
  .card:hover .card__description {
    max-height: 400px;
    opacity: 1;
  }
  .card__description {
    max-height: 0;
    opacity: 0;
    transition: opacity 0.3s ease, max-height 0.2s;
  }
  .card.hover .card__follow,
  .card:hover .card__follow {
    transform: translateY(0);
  }
  .card__follow {
    top: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    background-color: #f2d423;
    transform: translateY(37px);
    transition: transform 0.2s ease-in-out, background-color 0.2s ease;
  }
  .card__follow:hover {
    background-color: #dec00d;
    border-color: #dec00d;
  }
  
  
  @media only screen and (max-width: 600px) {
        .listbody > div{
          min-width: 340px;
        }
  
        .listbody > div:hover{
          min-width: calc(340px - 5px);
        }
  
        .conteiners {
          display: flex;
          padding: 0 10px;
          flex-wrap:wrap;
          overflow-y: scroll;
          /*overflow-y: scroll;*/
          width: 70%;
          height: 45%;
          position: absolute;
          top: 320px;
          left: 0;
        }
  
        .downmodal {
          display: block;
        }
  
        .cont_tabs {
          display: none;
        }
  
        .CtrlModalWidg {
          position: absolute;
          top: 75px;
          right: 120px;
          color: white;
      }
  
      .chartboxhistory {
        position: absolute;
        width: 90%;
        height: 200px;
        padding: 10px;
        left: 10px;
        z-index: 0;
      }
  
      .chartboxhistory2 {
        position: absolute;
        width: 230px;
        height: 250px;
        padding: 10px;
        top: 200px;
        left: 70px;
        z-index: 0;
      }
  
      .chartboxhistory3 {
        display: none;
      }
  
      .listboxhistory{
        position: absolute;
        width: 95%;
        height: 200px;
        padding: 10px;
        left: 0px;
        top: 450px;
        z-index: 0;
      }
  
      .HistotyCalender{
        position: relative;
        left: 70px;
      }
  
      .list_comp_hez {
        left: 3%;
        top: 60px;
        width: 95%;
    }
  
    .list_comp_pimg {
      width: 80px;
      height: 80px;
    }
  
    .list_comp_het{
      left: 150px;
      font-size: 20px;
      width: 50%;
  
    }
    .list_comp_hez{
      left: 150px;
      width: 50%;
    }
  
    .list_comp_numer{
      top: 30%;
      left: 5px;
      width: 50px;
      text-align: center;
    }
      
  }
  

  @media screen and (max-width: 700px) {
    table, tr, td {
      display: block;
    }
  
    td:first-child {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
    }
    td:not(:first-child) {
      clear: both;
      margin-left: 100px;
      padding: 4px 20px 4px 90px;
      position: relative;
      text-align: left;
    }
    td:not(:first-child):before {
      color: #91ced4;
      content: "";
      display: block;
      left: 0;
      position: absolute;
    }
    td:nth-child(2):before {
      content: "Name:";
    }
    td:nth-child(3):before {
      content: "Email:";
    }
    td:nth-child(4):before {
      content: "Phone:";
    }
    td:nth-child(5):before {
      content: "Comments:";
    }
  
    tr {
      padding: 10px 0;
      position: relative;
    }
    tr:first-child {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    .header {
      background-color: transparent;
      color: white;
      font-size: 2em;
      font-weight: 700;
      padding: 0;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    }
  
    /* img {
      border: 3px solid;
      border-color: #daeff1;
      height: 100px;
      margin: 0.5rem 0;
      width: 100px;
    } */
  
    td:first-child {
      background-color: #c8e7ea;
      border-bottom: 1px solid #91ced4;
      border-radius: 10px 10px 0 0;
      position: relative;
      top: 0;
      transform: translateY(0);
      width: 100%;
    }
    td:not(:first-child) {
      margin: 0;
      padding: 5px 1em;
      width: 100%;
    }
    td:not(:first-child):before {
      font-size: 0.8em;
      padding-top: 0.3em;
      position: relative;
    }
    td:last-child {
      padding-bottom: 1rem !important;
    }
  
    tr {
      background-color: white !important;
      border: 1px solid #6cbec6;
      border-radius: 10px;
      box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
      margin: 0.5rem 0;
      padding: 0;
    }
  
    .table-users {
      border: none;
      box-shadow: none;
      overflow: visible;
    }
  }