/* START calendar_info */ 

.DayCM{
  display: none;
}

.CalendarInfo{
  /* position: relative; */
  /* top: 0px;
  border: solid 0px red; */
  padding: 0px;
  height: 520px;
}

.CalendarInfo  .CalendarBox {
  width: 95%;
  height: 450px;
  /* top: 0px;  */
  border: solid 0px red;
  position: relative;
  font-family: "Lucida Console", "Courier New", monospace;
}

.CalendarInfo .SpaceCap {
  background-color: rgba(0, 0, 0, 0);
  border: solid 0px red;
  width: 5%;
  height: 20px;
  text-align: center;
  position: relative;
  float: left;
  margin: 2px;
  font-size: 14px;
}

.CalendarInfo  .DayCap {
  border: solid 0px red;
  width: 12.8%;
  height: 20px;
  text-align: center;
  position: relative;
  color: rgb(54, 162, 235);
  float: left;
  margin: 2px;
  font-size: 14px;
  line-height: 0px;

}

.CalendarInfo  .DayBoxFREE {
  width: 12.80%;
  height: 75px;
  position: relative;
  margin: 2px;
}

.CalendarInfo  .DayBox {
  width: 12.5%;
  height: 75px;
  text-align: center;
  position: relative;
  float: left;
  margin: 2px;
}

.CalendarInfo  .DB_laboral {
  border: 1px solid rgb(54, 162, 235);
  background-color: rgba(54, 162, 235, 0.2);
}

.CalendarInfo  .DB_falta {
  border: 1px solid rgb(255, 99, 132);
  background-color: rgba(255, 99, 132, 0.2);
}

.CalendarInfo  .DB_extra {
  border: 1px solid rgb(255, 206, 86);
  background-color: rgba(255, 206, 86, 0.2);
}

.CalendarInfo  .DB_puente {
  border: 1px solid rgb(221, 86, 255);
  background-color: rgba(255, 86, 241, 0.2);
}

.CalendarInfo  .DB_active {
  border: 1px solid rgb(134, 255, 86);
  background-color: rgba(139, 255, 86, 0.2);
}

.CalendarInfo .DateDayBox {
  font-size: 12px;
  text-align: center;
  position: absolute;
  color: rgb(255, 255, 255);
  top: 0%;
  left: 0%;
  margin: 1px;
  padding: 3px;
  width: 30%;
  pointer-events: none;
  background-color: rgb(0, 0, 0,0);
}

.CalendarInfo  .cattimeall {
  position: absolute;
  top: 5px;
  left: 15px;
  font-weight: normal;
  color: rgb(255, 255, 255);
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Lucida Console", "Courier New", monospace;
}

.CalendarInfo .CapHoras {
  font-size: 10px;
  position: absolute;
  color: rgb(218, 255, 176);
  top: 0px;
  left: 20%;
  margin: 1px;
  padding: 3px;
  width: 92%;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CalendarInfo .CapLugar {
  font-size: 10px;
  text-align: center;
  position: absolute;
  color: rgb(255, 255, 255);
  bottom: 0px;
  left: 0%;
  margin: 1px;
  padding: 3px;
  width: 92%;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CalendarInfo .WeekCap {
  border: solid 0px red;
  color: rgb(255, 206, 86);
  width: 5%;
  height: 75px;
  float: left;
  margin: 2px;
  font-size: 14px;
  text-align: center;
  line-height: 75px;
  /* transform: translateY(-50%); */
}

@media only screen and (max-width: 806px) {

  .DayCM{
    display: none;
  }

  .DayC{
    display: block;
  }

  .CalendarInfo{
    top: 125px;
  }

  .CalendarInfo  .CalendarBox {
    width: 90%;
  }

  .CalendarInfo .SpaceCap {
    width: 5%;
  }

  .CalendarInfo  .DayBoxFREE {
    width: 12.40%;
  }

  .CalendarInfo  .DayBox {
    width: 12%;
  }

  .CalendarInfo  .DayCap {
    width: 12.35%;
    height: 20px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 555px) {
  .DayCM{
    display: block;
  }

  .DayC{
    display: none;
  }

  .CalendarInfo{
    top: 225px;
  }

  .CalendarInfo .SpaceCap {
    width: 4%;
  }

  .CalendarInfo  .DayBoxFREE {
    width: 12.05%;
  }

  .CalendarInfo  .DayBox {
    width: 11.5%;
  }

  .CalendarInfo  .DayCap {
    width: 12%;
    height: 20px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 12px;
  }

  .empleado_info .ch1 {
    width: 90%;
  }

  .empleado_info .ch2 {
    top: 180px;
    width: 90%;
  }
}

@media only screen and (max-width: 414px) {
  .DayCM{
    display: block;
  }

  .DayC{
    display: none;
  }

  .CalendarInfo{
    top: 225px;
    height: 430px;
  }

  .CalendarInfo .SpaceCap {
    width: 4%;
  }

  .CalendarInfo  .DayBoxFREE {
    width: 11.85%;
    height: 45px;
  }

  .CalendarInfo  .DayBox {
    width: 11.3%;
    height: 45px;
  }

 


  .CalendarInfo  .DayCap {
    width: 12%;
    height: 20px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 12px;
  }

  .empleado_info .ch1 {
    width: 90%;
  }

  .empleado_info .ch2 {
    top: 180px;
    width: 90%;
  }

  .CalendarInfo  .cattimeall {
    position: absolute;
    top: 8px;
    left: 5px;
    font-size: 10px;
  }

  .CalendarInfo .WeekCap {
    width: 4%;
    height: 45px;
    line-height: 45px;
  }

}

/* END calendar_info */

.ScrollModalWidg {
  position: fixed;
  top: 10%;
  left: 10%;
  padding: 20px;
  background-color: rgba(0,0,0,0.9);
  color: white;
  height: 80%;
  width: 80%;
  z-index: 0;
  overflow-y: auto;
}

.selcompany-info {
    position: relative;
    width: 100%;
    height: auto;
    top: 0px;
    left: 0px;
    padding: 0px;
    margin: 0px;
    color: white;
  }

  .empleado_info .usrbox{
    position: absolute;
    width: 50%;
    height: 150px;
    padding: 5px;
    top: 30px;
    left: 120px;
  }
  .empleado_info .ch1 {
    position: absolute;
    width: 45%;
    height: 150px;
    padding: 5px;
    top: 50px;
    left: 20px;
  }

  .empleado_info .ch2 {
    position: absolute;
    width: 50%;
    height: 150px;
    padding: 5px;
    top: 50px;
    left: 45%;
  }

  .empleado_info .ch3 {
    display: block;
    width: 100%;
    height: auto;
    padding-top: 45px;
    top: 0px;
    left: 20px;
  }

  .empleado_info .ch4 {
    display: block;
    width: 80%;
    height: auto;
    padding-top: 120px;
    padding-left: 30px;

    top: 0px;
    left: 20px;
  }

  .empleado_info .ch5 {
    border: 0px solid var(--btn-col, #6698c8);
    width: auto;
    position: absolute;
    height: auto;
    right: 10px;
    top: 10px;
    align-content:center;
    text-align: right;
  }

  
  .empleado_info .modl_caption {
    position: relative;
    margin: 0px;
    padding: 0px;
    top: 0px;
    color:#fff;
	  text-transform:uppercase;
    font-size: 16px;
  }

  .empleado_info .ava_empleado {
    position: relative;
    top: 15px;
    left: 0px;
    width: 85px;
    border-radius: 25%;
    padding: 15px;
    float:left;
  }

  .empleado_info .chr_horas_empleado {
    position: relative;
    top: 0px;
    left: 0px;
    width: 120px;
    border-radius: 25%;
    padding: 15px;
    float:left;
  }

  .empleado_info .leg_horas_empleado {
    position: relative;
    top: 25px;
    left: 0px;
    padding-top: 15px;
    float:left;
  }

  .empleado_info .txt {
    margin: 0px;
    padding: 0px;
    top: 0px;
    color:rgb(214, 214, 214);
	  text-transform:uppercase;
    font-size: 18px;
  }

  .empleado_info .txt2 {
    margin: 0px;
    padding-left: 8px;
    top: 0px;
    color:rgb(214, 214, 214);
	  text-transform:uppercase;
    font-size: 12px;
    width: 100%;
  }

  @media only screen and (max-width: 444px) {
    .empleado_info .ch1 {
      width: 90%;
    }
  
    .empleado_info .ch2 {
      top: 180px;
      left: 20px;
      width: 90%;
    }

    .empleado_info .ch3 {
      padding-top: 160px;
      left: 20px;
      padding-bottom: 0px;
    }

    .empleado_info .ch4 {
      padding-top: 0px;
      left: 20px;
      padding-bottom: 460px;
      width: 80%;
    }

    .empleado_info .ch5 {
      border: 0px solid var(--btn-col, #6698c8);
      width: 90%;
      position: fixed;
      height: 100px;
      left: 50px;
      bottom: 20px;
      right: auto;
      top: auto;
      text-align: left;
    }
  }

  .backmini{ 
	color:#fff;
	background:linear-gradient(60deg, rgb(192, 130, 15) 0%, rgb(255, 166, 0) 63%, rgb(199, 121, 31) 100%);
    outline: none;
    width: 42px;
    height: 42px;
    border: 0;
	padding: 5px;
	text-transform:uppercase;
    font-size: 24px;
    text-align: center;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.backmini:hover {
    background:linear-gradient(60deg, rgb(255, 166, 0) 0%, rgb(255, 185, 56) 63%, rgb(255, 136, 0) 100%);
  }

  .btni{
    pointer-events: none

  }

  .listusr{
    position: relative;
    width: 100%;
    top: 5px;
  }
  
  .listcompan {
    position: relative;
    /*display: inline-block;*/
    top: 0px;
    left: 15px;
    /*background-color: rgba(0,0,0,0.2);*/
    height: 175px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.btnclientcard{ 
	color:#fff;
	background:linear-gradient(60deg, rgb(192, 130, 15) 0%, rgb(255, 166, 0) 63%, rgb(199, 121, 31) 100%);
    outline: none;
    width: 120px;
    height: 160px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
    display: inline-block;
}

.btnclientcard:hover {
    background:linear-gradient(60deg, rgb(255, 166, 0) 0%, rgb(255, 185, 56) 63%, rgb(255, 136, 0) 100%);
  }

  .listempleado {
    position: relative;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    height: 50px;
    background-color: rgba(50, 50, 50, 0.568);
    margin: 3px;
}

.listempleado:hover {
  background: var(--list-sel, #ffc400);
  border-left: 5px solid var(--btn-col, #6698c8);
}


.listempleado figure.uslist {
  border-radius: 0px;
  margin-top: 1px !important;
}

.listempleado figure.uslist:hover {
  background: var(--list-sel, #ffc400);
  border-left: 5px solid var(--btn-col, #6698c8);
}

.listmaket{
  list-style-type: none;
  height: 50px;
  width: 100%;
  position: relative;
}

.FindListSubcontr{
  width: 90%;
}

.listmaket .editbtn {
  position: absolute;
  display: inline-block;
  text-align: right;
  height: 35px;
  width: 350px;
  top: 0px;
  right: 0px;
  padding: 0px;
  margin: 3px;
  opacity: 0;
}

.listmaket:hover .editbtn {
    opacity: 1;
}

.listmaket:hover .btn_setings {
  height: 32px;
  width: 32px;
  top: 0px;
  right: 0px;
  font-size: 22px;
  margin: 5px;
  color: white;
  text-align: center;
  align-self: center;
  align-items: center;
  position: relative;
  border: solid 1px rgb(255, 255, 255);
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}

.btn_modal_tool {
  height: 32px;
  width: 32px;
  top: 0px;
  right: 0px;
  font-size: 21px;
  margin: 5px;
  padding-left: 3px;
  color: white;
  text-align: center;
  align-self: center;
  align-items: center;
  position: relative;
  border: solid 1px rgb(255, 255, 255);
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
}

.btn_modal_tool:hover {
  color: rgb(255, 174, 0);
  border: solid 1px rgb(255, 174, 0);
}

.listmaket:hover .btn_setings:hover {
  color: rgb(255, 174, 0);
  border: solid 1px rgb(255, 174, 0);
}

.btn_panel_modal_left_top{
  position: absolute;
  right: 18px;
  top: 30px;
}

  .wa {
    height: 22px;
    width: 22px;
    text-align: center;
    align-self: center;
    align-items: center;
  }

.list_empleo_pimg {
  z-index: 0;
  top: 5px;
  left: 55px;
  width: 41px;
  height: 41px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  background-size: cover;
}

.list_empleo_bimg {
  z-index: 0;
  top: 5px;
  left: -20px;
  width: 100px;
  height: 100px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
  background-size: cover;
}

.list_empleo_ecimg {
  z-index: 0;
  top: 5px;
  left: 0px;
  width: 65px;
  height: 65px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  border-radius: 10px;
  background-size: cover;
}


.list_client_pimg {
  top: 3px;
  right: 3px;
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 10%;
}

.list_empleo_het {
  position: absolute;
  opacity: 1;
  z-index: 10;
  color: #c5c5c5;
  text-shadow: 0px 0px #646464;
  display: inline-block;
  text-align: left;
  font-size: 20px;
  left: 105px;
  top: 3px;
  width: 70%;
  margin-top: 0px;
}


.list_empleo_hez {
  position: absolute;
  opacity: 1;
  z-index: 10;
  color: rgba(124, 124, 124, 0.568);
  display: inline-block;
  text-align: left;
  font-size: 12px;
  left: 110px;
  top: 30px;
  width: 80%;
  margin-top: 1px;
}

.list_empleo_numer {
  z-index: 1;
  text-align: center;
  position: absolute;
  width: 50px;
  font-size: 20px;
  color: var(--list-text, #e4e4e4);
    top: 3px;
    left: 3px;
}

.saback-client-box {
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  top: 60px;
  left: 0px;
  overflow-x: hidden;
}

.conteiner0 {
  overflow: hidden;
  /*overflow-y: scroll;*/
  width: calc(100% - 10px);
  height: auto;
  position: relative;
  top: 130px;
  left: 0px;
  
}

.conteiner_ce {
  overflow: hidden;
  /*overflow-y: scroll;*/
  width: calc(100% - 10px);
  height: 85%;
  position: relative;
  top: 100px;
  left: 0px;
  
}

.userc_name {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 100px;
  margin: auto;
}

@media only screen and (max-width: 600px) {

  .listmaket:hover {
    list-style-type: none;
    height: 85px;
    width: 100%;
    position: relative;
}

.empleado_info .ch5 {
  border: 0px solid var(--btn-col, #6698c8);
  width: 90%;
  position: fixed;
  height: 100px;
  left: 12.5%;
  bottom: 20px;
  right: auto;
  top: auto;
  text-align: left;
}

.listempleado:hover {
  background: var(--list-sel, #ffc400);
  border-left: 5px solid var(--btn-col, #6698c8);
}

  .listmaket:hover .listempleado {
    height: 80px;
}

.listmaket:hover .editbtn {
  top: 30px;
}

.listmaket:hover .list_empleo_numer {
  top: 10px;
}

.listmaket:hover .list_empleo_hez {
  left: 10px;
  top: 60px;
  width: 60%;
  margin-top: 1px;
}


}