
/* ====================== 2X ===========================*/

.containerin{
    position: relative;
    width: 100%;
    height: 100px;
    margin-top: 0;
    border: 0px solid red;
}

input[type="range"]{
  border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 60px;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
    background: transparent;
}

.slider-track{
    width: calc(100% - 40px);
    height: 15px;
    position: absolute;
    margin: auto;
    top: 60px;
    left: 20px;
    bottom: 0;
    border-radius: 0px;
    
}
input[type="range"]::-webkit-slider-runnable-track{
    -webkit-appearance: none;
    height: 5px;
    content: '+';
}
input[type="range"]::-moz-range-track{
    -moz-appearance: none;
    height: 5px;
    content: '+';
}

input[type="range"]::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 42px;
    width: 42px;
    background-color: #fcb322;
    cursor: pointer;
    margin-top: -19px;
    pointer-events: auto;
    border-radius: 10%;
    border: 1px solid #3b3b3b;
    color: teal;
    font-size: 18px;
    content: "\f040";
    font-family: 'FontAwesome';
}
input[type="range"]::-moz-range-thumb{
    -webkit-appearance: none;
    height: 42px;
    width: 42px;
    cursor: pointer;
    border-radius: 10%;
    background-color: #fcb322;
    border: 1px solid #3b3b3b;
    pointer-events: auto;
    
    font-size: 18px;
    content: "+";
    font-family: 'FontAwesome';
}

input[type="range"]:active::-webkit-slider-thumb{
    background-color: #ffffff;
    border: 0px solid #fcb322;
}

.dayvalues{
    top: 28%;
    background-color: #303e4d4d;
    width: 32%;
    right: 4%;
    position: absolute;
    margin: auto;
    padding: 5px 0;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
}
.dayvalues:before{
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-top: 15px solid #303e4d4d;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    margin: auto;
    bottom: -14px;
    left: 0;
    right: 0;
}
