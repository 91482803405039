@import url('fonts/font-awesome-4.7.0/css/font-awesome.min.css');

.light {
  --CalJL-fon: #727880; /* barmenu */  
  --CalJL-text: #4d3608; /* menu */
  --CalJL-fscolor: #14304e; /* lista-color */ 
  --CalJL-ficolor: #4e1414; /* lista-color */ 
  --CalJL-pucolor: #4e4d14; /* lista-color */ 
  --CalJL-vacolor: #03721b; /* lista-color */
  --CalJL-listback: #303e4d; /* barmenu */  
}

.dark {
  --CalJL-fon: #303e4d; /* barmenu */  
  --CalJL-text: #fcb322; /* menu */
  --CalJL-fscolor: #14304e; /* lista-color */ 
  --CalJL-ficolor: #4e1414; /* lista-color */ 
  --CalJL-pucolor: #4e4d14; /* lista-color */ 
  --CalJL-vacolor: #03721b; /* lista-color */
  --CalJL-listback: #303e4d; /* barmenu */  
}

:root {
  --column-bg: #303e4d; /* barmenu */  
  --borders-bg: #424952; /* barmenu */ 
  --menu-bg-hover: #2c3849; /* menu */ 
  --list-norm: #252525; /* menu */
  --list-sel: #3b3b3b; /* menu */
  --list-text: #7a7a7a; /* menu */
  --list-text-sel: #fcb322; /* menu */

  --active-item: #6698c8; /* menu */ 
  --active-item-text: #ffffff; /* menu */ 
  --hover-item: #4a5664; /* active list */ 
  --text-color: #ffffff; /* text-color */ 
  --fon-01: #ffffff; /* fondo 01 */ 
  --fon-02: #c7c7c7; /* fondo 01 */ 
  --li-text-color: #ffffff; /* lista-color */ 
  --li-mas-color: #22fc51; /* lista-color */ 
  --li-min-color: #fc6722; /* lista-color */ 
  --coment-text-color: #c7c7c7; /* comentario-color */ 
  --active-presence: #94e864; /* punto lista */ 
  --mention-badge: #78af8f; /* menu */ 
  --btn-col: #fcb322; /* buttons */ 
  --btn-sel: #fcd079; /* buttons */ 
  --btn-sdw: #b37f18; /* buttons */ 
  --menu-max: 325px; /* menu */ 
  --fonts-zoom: 50%; /* menu */ 
  --menu-page: "user"; /* menu */ 
  --trans-opacity: 0.98; /* opacity fff000 */ 

  --text-placeholder: #303e4d; /* barmenu */ 

  --trans-gcolorn: 0.65; /* opacity contact group norm*/ 
  --trans-gcolorh: 0.90; /* opacity contact group hover */

  --cgroup-color-001: #ff0000; /* buttons */ 
  --cgroup-color-002: #ff5100; /* buttons */ 
  --cgroup-color-003: #ffa600; /* buttons */ 
  --cgroup-color-004: #e5ff00; /* buttons */ 
  --cgroup-color-005: #48ff00; /* buttons */ 
  --cgroup-color-006: #00ff4c; /* buttons */ 
  --cgroup-color-007: #00d9ff; /* buttons */ 
  --cgroup-color-008: #008cff; /* buttons */ 
  --cgroup-color-009: #003cff; /* buttons */ 
  --cgroup-color-010: #7700ff; /* buttons */ 
  --cgroup-color-011: #f700ff; /* buttons */ 
  --cgroup-color-012: #cf2020; /* buttons */ 
  --cgroup-color-013: #cf9220; /* buttons */ 
  --cgroup-color-014: #6fcf20; /* buttons */
  --cgroup-color-015: #ffffff; /* buttons */
  --cgroup-color-016: #c7c7c7; /* buttons */
  --cgroup-color-017: #303e4d; /* buttons */
  --cgroup-color-018: #fcb322; /* buttons */
  --cgroup-color-019: #464646; /* buttons */
  --cgroup-color-020: #141414; /* buttons */
  --cgroup-color-021: #0e5e0e; /* buttons */ 
  --listuserold-max: 105;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /*-moz-osx-font-smoothing: auto;*/
  background-color: #191919; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  color: #48ff00;
}

.printdoc {
  color: #141414;
  width: 100%;
  height: 100%;
}

.fondo {
  position: absolute;
  width: 100%;
  height:calc(100% - 50px);
  left: 0px;
  top: 50px;
  padding: 0px;
  margin: 0px;
  /*background-image: linear-gradient(to right, #191919 , var(--column-bg));
  background-size: cover;
  background-position: center;
  background-attachment: fixed;*/
  background-color: #191919; 
  overflow-x: hidden;
}

.fondoGost {
  width: 100%;
  height:calc(100% - 50px);
  left: 0px;
  top: 50px;
  padding: 0px;
  margin: 0px;
  /*background-image: linear-gradient(to right, #191919 , var(--column-bg));
  background-size: cover;
  background-position: center;
  background-attachment: fixed;*/
  background-color: #191919; 
  overflow-x: hidden;
}

.mxicon{
  display:none;
}

.kmenu {
  position: absolute;
  display: inline-block;
  top: 20px;
  right: 70px;
}

button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  cursor: pointer;
}
button:focus, button:hover, button:active {
  outline: none;
}


nav .menu {
  width: 100%;
  height: 80px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  box-sizing: border-box;
  z-index: 3;
}

nav .menu .menu_links {
  font-family: sans-serif;
  transition: all 0.4s ease;
  opacity: 0.5;
}
nav .menu .menu_links:hover {
  opacity: 1;
}

a {
  margin: 0;
  padding: 0px;
  list-style: none;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  nav .menu .menu_links {
    display: none;
  }
}

nav .menu .menu_links .link {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 50px;
  letter-spacing: 2px;
  position: relative;
  transition: all 0.3s 0.2s ease;
}
nav .menu .menu_links .link:last-child {
  margin-right: 0;
}
nav .menu .menu_links .link:before {
  content: "";
  position: absolute;
  width: 0px;
  height: 4px;
  background: linear-gradient(90deg, #FFEDC0 0%, #FCB322 100%);
  bottom: -10px;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.82, 0.02, 0.13, 1.26);
  left: 100%;
}

nav .menu .menu_links .link:hover  {
  opacity: 1;
  color: #FCB322;
}

/* nav .menu .menu_links .link select {
  content: "";
  position: absolute;
  width: 0px;
  height: 4px;
  background: linear-gradient(90deg, #FFEDC0 0%, #FCB322 100%);
  bottom: -10px;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.82, 0.02, 0.13, 1.26);
  left: 100%;
} */

nav .menu .menu_links .select , nav .menu .menu_links {
  opacity: 1;
  color: #FCB322;
}

nav .menu .menu_links .link:hover:before {
  width: 40px;
  left: 0;
}

nav .menu .menu_icon {
  width: 40px;
  height: 40px;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {

  .mxicon{
    display:inline-block;
    font-size: 1.5em;
    color: #FFEDC0;
    padding: 25px;
  }

  .mxicon:hover{
    border-top: 2px solid #FCB322;
    background-color: rgba(201, 201, 201, 0.534);
    color: #363636;
    border-radius: 5px;
  }

  .mxicon::before{
    background-color: rgba(201, 81, 81, 0.534);

  }

  .mxicon:checked{
    background-color: rgba(38, 175, 118, 0.534);
  }

  .mxtext {
    /* Hide the text. */
    display:none;
}

  .kmenu {
    position: absolute;
    display: block;
    width: 85%;
    top: 80vh;
    left: 10px;
    margin: 20px;
    padding: 10px;
    z-index: 9999;
    /*background-color:  #ffffff59;*/

  }
}