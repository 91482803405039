/* TEST DRAG */  


.setlisttodo{
  margin: 5px;
  padding: 10px;
}

.moveclass{
  color: #bccdd6;
  width: 250px;
  height: 70px;
  background-color:#ce6209;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}

.moveclass:hover {
  background-color: #aa3636;
}

.normclass{
  color: #bccdd6;
  width: 250px;
  height: 70px;
  background-color:#0077b5;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}

.normclass:hover {
  background-color: #252525;
}

.editclass{
  color: #bccdd6;
  width: 250px;
  height: 70px;
  background-color:#0077b5;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}

.editclass:hover {
  background-color: #a37e7e;
}

.placeholder {
  /* background: rgb(255,240,120); */
        width: 100%;
        height: 35px;
        /* background-color: rgba(87, 87, 87, 0.568); */
        margin: 13px;
}

.placeholder:before {
    content: "Drop here";
    color: rgb(225,210,90);
}

/* Accordion styles */
.UserMainCaption {
  position: relative;
  top: 0px;
  display: block;
  font-size: 16px;
  color: #FCB322;
  padding: 8px;
  width: 90%;
  height: auto;
  word-wrap: break-word;
}

.txtEmpresa{
  position: relative;
  font-size: 16px;
  text-align: left;
  left: 100px;
  top: 10px;
  padding: 0px 0 9px 0;
  margin: 0;
  color: rgb(192, 192, 192);
  text-transform: uppercase;
  width: auto;
  height: 10px;
  display: inline-block;
}

.inputtabs {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tabs {
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
  margin: 8px;
}

.tab {
  width: 100%;
  color: #fcb322;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #353535;
  font-weight: bold;
  cursor: pointer;
  height: 35px;
  /* Icon */
}
.tab-label:hover {
  background: #7a7a7a;
}
.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color:  #c7c7c7;;
  background: #252525;
  transition: all 0.35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #252525;
}

input:checked + .tab-label {
  background: #3b3b3b;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

/* PERFIL */

.aboutempresa{
  border-radius: 5px;
  margin: 10px;
  top: 0px;
  width: 98%;
  height: 150px;
  border: 1px solid rgb(105, 105, 105);
  --opacidad-negro: 0.65;
  background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro)), rgba(0, 0, 0, var(--opacidad-negro))), url('https://inupline.com/ssd/cv/bf_89.jpg');
}

.aboutempresa .logo {
  position: absolute;
  height: 84px;
  width: 84px;
  left: 25px;
  top: 25px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}

.aboutempresa .txtEmpresa{
  position: relative;
  font-size: 20px;
  text-align: left;
  left: 125px;
  top: 20px;
  padding: 0px 0 9px 0;
  margin: 0;
  color: rgb(192, 192, 192);
  text-transform: uppercase;
  width: auto;
  height: 40px;
  display: inline-block;
}

.aboutempresa .g1{
  position: relative;
  font-size: 14px;
  text-align: right;
  right: 0px;
  bottom: 20px;
  padding: 0px 0 9px 0;
  margin: 0;
  color: rgb(192, 192, 192);
  text-transform: uppercase;
  width: auto;
  height: 40px;
  display: block;
}

.aboutempresa .g1 .c1{
  position: relative;
  font-size: 14px;
  text-align: right;
  right: 25px;
  top: 0px;
  padding: 0px 0 9px 0;
  margin: 0;
  color: rgb(192, 192, 192);
  text-transform: uppercase;
  width: auto;
  height: 10px;
}

.aboutempresa .txtEmpresaId{
  position: relative;
  font-size: 14px;
  text-align: left;
  left: 130px;
  top: 0px;
  padding: 0px 0 9px 0;
  margin: 0;
  color: rgb(192, 192, 192);
  text-transform: uppercase;
  width: 200px;
  height: 10px;
  display: block;
}

.mycompbutcontl{
  position: relative;
  display: inline-block;
  top: 0px;
  left: 5px;
  /*background-color: rgba(0,0,0,0.2);*/
  height: calc(100% + 220px);
  width: 98%;
  border: 0px solid red;
  align-items: center;
}

.mycompbutcontl .caption {
  position: relative;
  font-size: 18px;
  text-align: left;
  left: 15px;
  top: 0px;
  padding: 0px 0 9px 0;
  margin: 0;
  color: rgb(192, 192, 192);
  text-transform: uppercase;
  width: 200px;
  height: 20px;
  display: block;
}

.mycompbutcontl .btnboxcard{ 
  width: 23.65%;
}


.load-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  /*
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.849);*/
}

.load-3 .line:nth-last-child(1) {
  animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .line:nth-last-child(2) {
  animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .line:nth-last-child(3) {
  animation: loadingC 0.6s 0.3s linear infinite;
}

@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.line {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 3px;
  border-radius: 15px;
  background-color: #fcb322;
}

.fonback-box {
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
  top: 50px;
  left: 0px;
  overflow-x: hidden;
}

.cvback-box {
  position: relative;
  color: white;
  background: var(--btn-col);
  /*background-image: url("main/img/n003.jpeg");*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 175px;
  background-size: cover;
  top: 0px;
  left: 0px;
  height: 275px;
  opacity: 1;
  z-index: 0;
}

.cvback-box .iconsh {
  left: calc(6% - 10px);
  top: 45px;
  width: 195px;
  height: 195px;
  position: absolute;
  border-radius: 100%;
  background: var(--fon-01);
  opacity: 0.3;
}

.cvback-box .icon {
  left: 6%;
  top: 55px;
  width: 175px;
  height: 175px;
  position: absolute;
  border-radius: 100%;
  background-size: cover;
  margin: 0px;
  opacity: 1;
}

.cvback-box input.iconFon { 
	position: absolute; 
    left: 0px;
    top: 0px;
	opacity: 0;
	border: solid; 
	  width: 50px;
    height: 42px;
    cursor: pointer;
}

.cvback-box input.iconAva {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  font-size: 1px;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 175px;
  height: 175px;
  cursor: pointer;
}

.cvback-box .fname {
  left: calc(5% + 230px);
  top: 90px;
  position: absolute;
  font-size: 48px;
  padding: 10px 10px 0;
  text-transform: uppercase;
}

.cvback-box .lname {
  left: calc(5% + 240px);
  top: 150px;
  position: absolute;
  font-size: 20px;
  padding: 10px 10px 0;
}

.cvback-box .Qrstatus{
  right: 15px;
  top: 5px;
  position: absolute;
}

.cvback-box .qr-code{
  right: 25px;
  top: 90px;
  width: 135px;
  height: 135px;
  position: absolute;
  filter: invert(100%);
  mix-blend-mode: screen;
}

.cvback-box .sh {
  position: relative; /* fixed absolute relative static*/ 
  top: 0px;
  left: 0px;  
  height: 275px;
  background: linear-gradient(rgba(0, 0, 0, 0.30),rgba(0, 0, 0, 0.9));
  border-bottom: 0 solid var(--fon-01);
  /*box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);*/
  opacity: 1;
}

.cvback-box .panel {
  float: left;
  position: absolute; /* fixed absolute relative static*/ 
  top: 90px;
  left: calc(1% + 1px);  
  height: 275px;
  width: 64px;
}

.cvback-box .url_link {
  left: calc(10% + 190px);
  bottom: 40px;
  position: absolute;
}

.cvback-box .red_key {
  height: 40px;
  width: 40px;
  font-size: 32px;
  color: white;
  padding: 0px;
}

.cvback-box .buton:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.cvback-box .btn-themap:hover {
  color: #212529;
  background-color: var(--btn-sel, #ae0001);
  border-color: var(--btn-sel, #ae0001);
}
.cvback-box .buton:hover {
  color: #212529;
  text-decoration: none;
}
/* USER CARD FRAME*/

.imgprv{
	display: grid;
	margin: 0px auto;
	align-items:center;
	align-self: center;
	justify-content: center;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 10px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 20px;
  z-index: 1;
  top: 20px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--btn-col);
  color: #000000;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  color: #757575;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

/*  */
.avatar-upload .avatar-reset {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 20px;
}

.avatar-upload .avatar-reset label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--li-min-color);
  color: #000000;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-reset label:hover {
  background: #f1f1f1;
  color: #757575;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-reset label:after {
  content: "\f1f8";
  font-family: 'FontAwesome';
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-volver {
  position: absolute;
  left: -10px;
  z-index: 1;
  top: 60px;
}

.avatar-upload .avatar-volver label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--active-item);
  color: #000000;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-volver label:hover {
  background: #f1f1f1;
  color: #757575;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-volver label:after {
  content: "\f0e2";
  font-family: 'FontAwesome';
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-save {
  position: absolute;
  left: -20px;
  z-index: 1;
  top: 110px;
}

.avatar-upload .avatar-save label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--li-mas-color);
  color: #000000;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-save label:hover {
  background: #f1f1f1;
  color: #757575;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-save label:after {
  content: "\f0c7";
  font-family: 'FontAwesome';
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}


.avatar-upload .avatar-preview {
  pointer-events: none;
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  top: 15px;
  margin-right: -7%;
}
.avatar-upload .avatar-preview > div {
  pointer-events: none;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.separ {
  padding: 10px;
}


/* CHECBOX */

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
}
.styled-checkbox:hover + label:before {
  background: #fcb322;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #fcb322;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
/*/////////////////*/
.sltienda {
  top: 5%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: 1;
}

.bcard{
  background-color: rgba(126, 123, 123, 0.2);
  color: darkgrey;
  border-radius: 5px;
  position: relative;
  margin: 5px;
  width: 87%;
  height: auto;
  padding: 5px;
}

.bcard p{ 
  margin: 5px;
}
.h1welcom{
  position: relative;
  padding: 10px;
  margin: 0px;
}

.user_card{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.userc_image {
  display: block;
  width: 100%;
  height: auto;
  filter: blur(10px) brightness(50%) contrast(180%);

  /*filter: brightness(50%);*/
}

.authexit {
  color: rgb(248, 248, 248);
  font-size: 20px;
  position: absolute;
  padding: 0px;
  margin: 0px;
  top: 0px;
  right: 10px;
  background: #e45f06;
  border-radius: 10px;
  width: 50px;
  text-align: center;
}

.userc_ava {
  position: absolute;
  top: 100px;
  left: 35%;
  width: 30%;
  border-radius: 50px;
  filter: brightness(90%);
  /*box-shadow: 0px 0px 7px rgb(255, 255, 255);*/
}

.userc_welcom {
  color: rgb(163, 163, 163);
  font-size: 20px;
  position: absolute;
  top: 0px;
  left: 10px;
}

.userc_name {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 180px;
  left: 27%;
}

h2 {
  margin: 5px;
  padding: 5px;
  color: wheat;
}

.paragraf01 {
  margin: 10px;
  padding-left: 15px;
  color: var(--btn-col,#FCB322);
  font-size: 1rem;
}

.redmail {
  background: #dd4b39;
  color: #fff;
  cursor: pointer;
  font-size: 2em;
  padding: 1rem;
  margin: 5px;
  border: 0;
  border-radius: 5px;
  width: auto;
  position: relative;
}

.redahatsapp {
  background: #25d366;
  color: #fff;
  cursor: pointer;
  font-size: 2em;
  padding: 1rem;
  margin: 5px;
  border: 0;
  border-radius: 5px;
  width: auto;
  position: relative;
}

.redlinkedin {
  background: #0077b5;
  color: #fff;
  cursor: pointer;
  font-size: 2em;
  padding: 1rem;
  margin: 5px;
  border: 0;
  border-radius: 5px;
  width: auto;
  position: relative;
}

.logincontainer {
  margin: 0px 10px;
  width: 90%;
  height: 100%;
  top: calc(35%);
}

.modalcap {
  text-align: left;
  margin: 10px;
  font-size: calc(8px + var(--fonts-zoom, +50%));
  color: var(--btn-col,#FCB322);
}

.btnboxfb{ 
	color:#fff;
	background-color:#3b5998;
    outline: none;
    width: 90px;
    height: 90px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:5px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.btnboxgoogle{ 
	color:#fff;
	background-color:#4285f4;
    outline: none;
    width: 90px;
    height: 90px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:5px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.btnbox{
	color:#fff;
	background-color:#FCB322;
    outline: none;
    width: 90px;
    height: 90px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:5px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.btnboxcard:hover .btn_label, .btnboxcard:hover .btn_icon{
  color:rgb(68, 68, 68);
  background:linear-gradient(60deg, rgb(248, 172, 31) 0%, rgb(146, 96, 3) 63%, rrgb(255, 137, 2)100%);
}



.btn_label {
  position: absolute;
  display: block;
  font-size: 14px;
  width: calc(98%);
  left: 0;
  bottom: 10px;
  text-align: center;
  /*color: #303e4d;*/
  font-family: "Myriad Pro", sans-serif;
  font-weight: 700;
  pointer-events: none;
}

.btn_icon {
  text-align: center;
  position: relative;
  left:0px;
  top:-10px;
  font-size: 55px;
  pointer-events: none;
  /*color: #303e4d;*/
}

.glform {
  position: absolute;
  width: 73%;
  max-width: 300px;
  background-color: black;
  padding: 20px;
  height: auto;
  left: 7%;
  top: 20%;
  overflow: none;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  /*border-bottom: 5px solid var(--btn-col,#FCB322);*/
  z-index: 1;
}

.glform80 {
    position: absolute;
    width: 80%;
    background-color: black;
    padding: 20px;
    height: 500px;
    left: 10%;
    top: 90px;
    overflow: none;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-bottom: 5px solid var(--btn-col,#FCB322);
    z-index: 1;
}

.paragraf00 {
    padding: 0 30px;
    width: 80%;
}

.paragraf00li {
    margin: 3px;
    padding: 3px;
    color: var(--btn-col,#FCB322);
    font-size: 1rem;
    cursor: pointer;
}

.polygonlogo {
  position: fixed;
  bottom: -50px;
  left: 0px;
  clip-path: polygon(0 70%, 0 0, 100% 100%, 30% 100%);
  height: calc(500px);
  min-height: 400px;
  width:  calc(500px );
  min-width: 400px;
  background: linear-gradient(to top right, #FCB322, #FCB322);
  z-index: 1;
}

.logog0 {
    position: absolute;
    margin: 30px;
    width: 50%;
    height: auto;
    top: 20%;
    left: 25%;
    z-index: 9999;
  }
  
  .logog1 {
    position: absolute;
    font-size: 82px;
    color: #fff;
    z-index: 9999;
    top: 10px;
    left: -85px;
  
  }
  
  .logog2in {
    position: absolute;
    font-weight: 900;
    color: #FCB322;
    font-size: 200px;
    top: 50px;
    left: -15px;
  }
  
  .logog2l {
    position: absolute;
    width: 128px;
    left: 190px;
    top: 125px;
  }
  .logog2line {
    position: absolute;
    color: #fff;
    font-size: 200px;
    left: 330px;
    top: 50px;
  }
  
  .logog3 {
    position: absolute;
    font-size: 42px;
    color: var(--btn-col,#FCB322);
    z-index: 9998;
    top: 270px;
    left: 320px;
    width: 500px;
  }

  .profile-btn {
    width: 128px;
  }

  .profile-text {
    color: var(--btn-col,#FCB322);
  }
  
  .wrapper-box{
    position: absolute;
    top: 40%;
    left: 50%;
  }

  .wrapper .container {
    margin: 0 auto;
    transition: all 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

    .wrapper .container .one .content,
  .wrapper .container .two .content,
  .wrapper .container .three .content {
    width: 600px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: content 0.8s cubic-bezier(1, 0.06, 0.25, 1) backwards;
  }
  @keyframes content {
    0% {
      width: 0;
    }
  }
  .wrapper .container .one .content .piece,
  .wrapper .container .two .content .piece,
  .wrapper .container .three .content .piece {
    width: 200px;
    height: 80px;
    display: flex;
    position: absolute;
    border-radius: 80px;
    z-index: 1;
    animation: pieceLeft 8s cubic-bezier(1, 0.06, 0.25, 1) infinite both;
  }
  @keyframes pieceLeft {
    50% {
      left: 80%;
      width: 10%;
    }
  }
  @keyframes pieceRight {
    50% {
      right: 80%;
      width: 10%;
    }
  }
    
  .wrapper .container .text {
    width: 50%;
    height: 40%;
    min-width: 400px;
    min-height: 500px;
    position: absolute;
    margin: 0px 0;
    animation: text 0.6s 1.8s ease backwards;
  }
  @keyframes text {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }
  }
  
  .wrapper .container .text article {
    width: 300px;
    position: absolute;
    bottom: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .wrapper .container .text article p {
    color: white;
    font-size: 18px;
    letter-spacing: 0.6px;
    margin-bottom: 40px;
    text-shadow: 6px 6px 10px #32243E;
  }
  .wrapper .container .text article button {
    height: 40px;
    padding: 0 30px;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 15px 20px rgba(54, 24, 79, 0.5);
    z-index: 3;
    color: #695681;
    background-color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    transition: all 0.3s ease;
  }
  .wrapper .container .text article button:hover {
    box-shadow: 0px 10px 10px -10px rgba(54, 24, 79, 0.5);
    transform: translateY(5px);
    background: #FB8A8A;
    color: white;
  }
  .wrapper .container .p404 {
    font-size: 200px;
    font-weight: 700;
    letter-spacing: 4px;
    color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    left: -200px;
    top: -330px;
    animation: anime404 0.6s cubic-bezier(0.3, 0.8, 1, 1.05) both;
    animation-delay: 1.2s;
  }
 
  @keyframes anime404 {
    0% {
      opacity: 0;
      transform: scale(10) skew(20deg, 20deg);
    }
  }
  .wrapper .container .p404:nth-of-type(2) {
    color: #36184F;
    z-index: 1;
    animation-delay: 1s;
    filter: blur(10px);
    opacity: 0.8;
  }

  .wrapper .container .one .content .piece {
    background: linear-gradient(90deg, #8077EA 13.7%, #EB73FF 94.65%);
  }
  .wrapper .container .one .content .piece:nth-child(1) {
    right: 15%;
    top: 18%;
    height: 30px;
    width: 120px;
    animation-delay: 0.5s;
    animation-name: pieceRight;
  }
  .wrapper .container .one .content .piece:nth-child(2) {
    left: 15%;
    top: 45%;
    width: 150px;
    height: 50px;
    animation-delay: 1s;
    animation-name: pieceLeft;
  }
  .wrapper .container .one .content .piece:nth-child(3) {
    left: 10%;
    top: 75%;
    height: 20px;
    width: 70px;
    animation-delay: 1.5s;
    animation-name: pieceLeft;
  }
  .wrapper .container .two .content .piece {
    background: linear-gradient(90deg, #FFEDC0 0%, #FF9D87 100%);
  }
  .wrapper .container .two .content .piece:nth-child(1) {
    left: 0%;
    top: 25%;
    height: 40px;
    width: 120px;
    animation-delay: 2s;
    animation-name: pieceLeft;
  }
  .wrapper .container .two .content .piece:nth-child(2) {
    right: 15%;
    top: 35%;
    width: 180px;
    height: 50px;
    animation-delay: 2.5s;
    animation-name: pieceRight;
  }
  .wrapper .container .two .content .piece:nth-child(3) {
    right: 10%;
    top: 80%;
    height: 20px;
    width: 160px;
    animation-delay: 3s;
    animation-name: pieceRight;
  }
  .wrapper .container .three .content .piece {
    background: #FB8A8A;
  }
  .wrapper .container .three .content .piece:nth-child(1) {
    left: 25%;
    top: 35%;
    height: 20px;
    width: 80px;
    animation-name: pieceLeft;
    animation-delay: 3.5s;
  }
  .wrapper .container .three .content .piece:nth-child(2) {
    right: 10%;
    top: 55%;
    width: 140px;
    height: 40px;
    animation-name: pieceRight;
    animation-delay: 4s;
  }
  .wrapper .container .three .content .piece:nth-child(3) {
    left: 40%;
    top: 68%;
    height: 20px;
    width: 80px;
    animation-name: pieceLeft;
    animation-delay: 4.5s;
  }



  @media screen and (max-width: 920px) {
    .logog0 {
      position: absolute;
      margin: 30px;
      width: 50%;
      height: auto;
      top: 20%;
      left: 20%;
      z-index: 9999;
    }

    
.logog2in {
  position: absolute;
  font-weight: 900;
  color: #FCB322;
  font-size: 10em;
  top: 0.1em;
  left: -0.2em;
}

.logog2l {
  position: absolute;
  width:  6em;
  left:  8em;
  top: 75px;
}

.logog2line {
  position: absolute;
  color: #fff;
  font-size: 10em;
  left: 1.4em;
  top: 0.1em;
}

.logog1 {
  position: absolute;
  font-size: 4em;
  color: #fff;
  z-index: 9999;
  top: -0.3em;
  left: -1.4em;
  width: 300px;
}

.logog3 {
  position: absolute;
  text-align: right;
  font-size: 2em;
  color: var(--btn-col,#FCB322);
  z-index: 9998;
  top: 6em;
  left: 5.7em;
  width: 400px;
}

  }


  @media screen and (max-width: 799px) {
    .wrapper .container .text {
      min-height: 300px;
      height: 80%;
    }

    .wrapper .container .p404 {
      position: absolute;
      left: -100px;
      top: 0px;
    }

    .wrapper .container .text article {
      width: 80%;
      left: 30%;
    }

    .wrapper .container .one,
  .wrapper .container .two,
  .wrapper .container .three {
      width: 100%;
      height: 100%;
      top: 5%;
      left: 5%;
      min-width: 280px;
      min-height: 280px;
    }

    .wrapper-box{
      position: absolute;
      top: 0%;
      left: 30%;
    }
  }

  @media only screen and (max-width: 755px) {
    .mycompbutcontl .btnboxcard{ 
      width: 31.7%;
    }
  }

@media only screen and (max-width: 623px) {

  .mycompbutcontl{
    height: calc(100% + 320px);
  }

  .mycompbutcontl .btnboxcard{ 
    width: 29.65%;
  }

  .breadcrumb li.active a{
    position: relative;
    font-size: 18px;
    width: 300px;
    top: 5px;
  }
  
  .aboutempresa{
    width: 91%;
    padding: 3px;
  }

  h2 {
    margin: 5px;
    padding: 0px;
    color: wheat;
  }

  .glform80 {
    position: absolute;
    width: 85%;
    background-color: black;
    padding: 10px;
    height: auto;
    left: 5%;
    top: 130px;
    overflow: none;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    /*border-bottom: 5px solid var(--btn-col,#FCB322);*/
    z-index: 1;
  }

  .paragraf00 {
    padding: 0 5px;
    width: 95%;
    font-size: 14px;
  }

  .paragraf00li {
    margin: 1px;
    padding: 1px;
    color: var(--btn-col,#FCB322);
    font-size: 12px;
    cursor: pointer;
  }

.logog0 {
    position: absolute;
    margin: 30px;
    width: 80%;
    height: auto;
    top: 10%;
    left: 2%;
    z-index: 9999;
}

.logog2in {
    position: absolute;
    font-weight: 900;
    color: #FCB322;
    font-size: 64px;
    top: 50px;
    left: 30px;
}

.logog2l {
    position: absolute;
    width: 40px;
    left: 90px;
    top: 68px;
}

.logog2line {
    position: absolute;
    color: #fff;
    font-size: 64px;
    left: 135px;
    top: 50px;
}

.logog1 {
    position: absolute;
    font-size: 32px;
    color: #fff;
    z-index: 9999;
    top: 28px;
    left: -6px;
    width: 300px;
}

.logog3 {
    position: absolute;
    text-align: right;
    font-size: 26px;
    color: var(--btn-col,#FCB322);
    z-index: 9998;
    top: 130px;
    left: -10px;
    width: 300px;
}

.sltienda {
  position: absolute;
  top: 10%;
  left: 5%;
  width: 80%;
  height: 80%;
  z-index: 1;
}
}

@media only screen and (max-width: 320px) {
  .mycompbutcontl .btnboxcard{ 
    width: 96%;
  }
}