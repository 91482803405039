/* $mobile-height: 90vh; */

:root {
  --marg-lft: 12.5%; /* barmenu */  
}

   
  
  .main-header {
    overflow: hidden;
    background-color: #fcb322;
    color: #fff;
  }

  .minimap {
    position: relative;
    height: 200px;
    transition: height 0.5s cubic-bezier(0.75, 1.75, 0.9, 0.8);
    background-color: #fcb322;
    color: #fff;
    overflow-x: auto;
    overflow-y: visible;
  }
  .is-mini .minimap {
    height: 75px;
    transition-timing-function: ease;
  }
  .is-hidden .minimap {
    height: 0;
    transition-timing-function: ease;
  }
  
  .mini-lists {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 50%;
    margin: auto 85% auto 15%;
    list-style-type: none;
    text-align: center;
    white-space: nowrap;
    transition: transform 0.3s;
    transform-origin: 0 50%;
  }
  .is-mini .mini-lists {
    transform: scaleX(0.375);
  }
  
  .mini-list {
    position: relative;
    display: inline-block;
    width: 60px;
    vertical-align: top;
    cursor: pointer;
  }
  .mini-list::before, .mini-list::after {
    transition: opacity 0.2s;
  }
  .mini-list::before {
    content: attr(data-title);
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    font-size: 12px;
    line-height: 1;
    transform: translateY(-50%);
    white-space: normal;
    opacity: 0.8;
  }
  .is-mini .mini-list::before {
    opacity: 0;
    visibility: hidden;
  }
  .mini-list::after {
    content: "";
    display: block;
    background-color: #fff;
    max-width: 50%;
    height: 100%;
    margin: auto;
    border-radius: 5px;
    opacity: 0.3;
  }
  .mini-list:hover::after {
    opacity: 0.5;
  }
  .mini-list.active::before, .mini-list.active::after {
    opacity: 1;
  }
  
  .lists-wrap {
    position: relative;
  }
  .lists-wrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 72px;
    /*background-image: linear-gradient(to bottom, #fcb322 0, #fcb322 48px, rgba(0, 0, 0, 0.2) 48px, transparent 57.6px);*/
  }
  
  .lists {
    position: relative;
    white-space: nowrap;
    letter-spacing: normal;
    transition: transform 0.5s;
    padding-top: 10px;
  }
  
  .list {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin: 0 2.5%;
    background-color: #e7ebed;
    border-radius: 5px;
  }
  .list:nth-of-type(1) {
    margin-left: var(--marg-lft);
  }
  
  .list-header {
    position: relative;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #e7ebed;
    color: rgba(0, 0, 0, 0.5);
    cursor: -webkit-grab;
    border-radius: 5px 5px 0 0;
    margin: 5px;
  }
  .list-header::-moz-selection {
    background-color: transparent;
  }
  .list-header::selection {
    background-color: transparent;
  }
  
  .primary-plus {
    position: absolute;
    right: 5%;
    top: 100%;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    color: #fff;
    transform: translateY(-50%);
    text-align: center;
    font-style: normal;
    font-size: 1.5em;
    font-weight: 100;
    background-color: #ccc;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  .primary-plus:hover {
    background-color: #c7c7c7;
  }
  .primary-plus:active {
    margin-left: 1px;
    margin-top: 1px;
    background-color: #bfbfbf;
  }
  
  .list-items {
    max-height: calc(100vh - 325px);
    padding-top: 16px;
    overflow: auto;
    transition: max-height 0.3s;
    will-change: max-height;
  }
  .is-mini .list-items {
    max-height: calc(100vh - 200px);
  }
  .is-hidden .list-items {
    max-height: calc(100vh - 125px);
  }
  
  .list-item {
    display: flex;
    min-height: 3em;
    padding: 10px;
    margin: 0.5em;
    background-color: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  
  .list-avatar {
    border-radius: 5px;
  }
  
  .list-tags {
    flex-grow: 1;
  }
  
  .list-tag {
    display: inline-block;
    width: 3em;
    margin-right: 5px;
    border-bottom: 5px solid;
    border-radius: 5px;
  }
  
  .list-thumb {
    flex-grow: 1;
    margin: -10px -10px 0;
    border-radius: 5px 5px 0 0;
    padding-bottom: 50%;
    min-height: 80px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  
  .list-tag--1 {
    border-color: #ffee99;
  }
  
  .list-tag--2 {
    border-color: #bbff99;
  }
  
  .list-tag--3 {
    border-color: #99ffcc;
  }
  
  .list-tag--4 {
    border-color: #99ddff;
  }
  
  .list-tag--5 {
    border-color: #aa99ff;
  }
  

  /*   */


 .content {
  /*position: absolute;*/
    top: 0;
    margin: 0px;
    top: 0;
    width: 100%;
    height: calc(100% + 140px); 
  overflow-y: hidden;
  overflow-x: hidden;
  color: rgb(189, 189, 189);
}

 .content ul,
 .content li {
  list-style: none;
  margin: 0;
  padding: 0;
}

 .content li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  padding: 0px;
  position: relative;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  height: 105px;
  opacity: 1;
}

 .content li.removed {
  overflow: hidden;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0;
}

 .content li .toolbox {
  position: absolute;
  right: -100px;
  top: 0;
  width: 80px;
  bottom: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  font-size: 30px;
  padding: 10px;
  color: #fff;
}

.content li .toolfirm {
  position: absolute;
  left: -100px;
  top: 0;
  width: 80px;
  bottom: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  font-size: 30px;
  padding: 10px;
  color: #fff;
}

.pusername{
  font-size: 24px;
  top: 0px;
  left: 115px;
  width: auto;
  height: 80px;
  position: absolute;
  padding: 10px;
  text-transform: uppercase;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: transparent;
  color: #fff;

  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

}

.pcompany{
  font-size: 14px;
  top: 20px;
  left: 115px;
  width: auto;
  height: 80px;
  position: absolute;
  padding: 20px;

  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  color: #f8dfae;

}

.plugar{
  font-size: 14px;
  top: 40px;
  left: 115px;
  width: auto;
  height: 80px;
  position: absolute;
  padding: 20px;

  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  color: #ffce6c;

}

.pdate{
  font-size: 14px;
  top: 60px;
  left: 115px;
  width: auto;
  height: 80px;
  position: absolute;
  padding: 20px;

  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  color: #fcb322;

}
.nsem{
  position: absolute;
  width: 250px;
  height: 40px;
  top: 70px;
  left: 10px;
  pointer-events: none;
}

.dsem{
  position: absolute;
  width: 250px;
  height: 40px;
  top: 40px;
  left: 10px;
  pointer-events: none;
}

.sen{
  display: inline-block;
  position: relative;
  width: 33px;
  height: 33px;
  font-size: 14px;
  margin: 2px;
  text-align: center;
  border-radius: 5px;

  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  
  color: transparent;
  pointer-events: none;
  background-color: transparent;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

}

.sem{
  display: inline-block;
  position: relative;
  width: 33px;
  height: 50px;
  font-size: 24px;
  margin: 2px;
  padding-top: 5px;

  text-align: center;
  border-radius: 5px;

  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

  background-color: transparent;
  color: transparent;
  border: 0px solid transparent;
  pointer-events: none;
}

.phoras{
  font-size: 32px;
  top: 20px;
  right: 10px;
  width: auto;
  height: 80px;
  position: absolute;
  padding-right: 10px;

  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.list_partes_user {
  top: 13px;
  left: 25px;
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 10%;
  pointer-events: none;

  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

}

.content li div.toolfirm:nth-child(1) {
  background-color: #539e3c;
}

 .content li div.toolbox:nth-child(5) {
  background-color: #ff3700;
}

 .content li div.toolbox:nth-child(3) {
  background-color: #ff7b52;
}

 .content li div.toolbox:nth-child(4) {
  background-color: #ff9a7b;
}

 .content li .box{
  pointer-events: none;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

 .content li.animate, .content li.animate2 {
  background-color: #e5e5e55d;
}

 .content li.animate .box{
  -webkit-transform: translate(-100px,0);
  -moz-transform: translate(-100px,0);
  -o-transform: translate(-100px,0);
  -ms-transform: translate(-100px,0);
  transform: translate(-100px,0);
  text-shadow: 0 0 5px rgba(0,0,0,0.3);
  color: transparent;
}

.content li.animate2 .list_partes_user
{
  -webkit-transform: translate(-20px,-10px);
  -moz-transform: translate(-20px,-10px);
  -o-transform: translate(-20px,-10px);
  -ms-transform: translate(-20px,-10px);
  transform: translate(-20px,-10px);
  width: 34px;
  height: 34px;
}

.content li.animate2 .pusername
{
  -webkit-transform: translate(-80px,-5px);
  -moz-transform: translate(-80px,-5px);
  -o-transform: translate(-80px,-5px);
  -ms-transform: translate(-80px,-5px);
  transform: translate(-80px,-5px);
}

.content li.animate2 .pcompany,
.content li.animate2 .plugar,
.content li.animate2 .pdate
{
  text-shadow: 0 0 5px rgba(0,0,0,0.3);
  color: transparent;
}

.content li.animate2 .sd6,
.content li.animate2 .sd7 {
  background-color: #fcb322;
}

.content li.animate2 .sd1,
.content li.animate2 .sd2,
.content li.animate2 .sd3,
.content li.animate2 .sd4,
.content li.animate2 .sd5 {
  background-color: #ffce6c;
}


.content li.animate2 .sem{
  color: #4e4e4e;
  /*background-color: #f8dfae;*/
}

.content li.animate2 .sen{
  color: #838383;
}



.content li.animate2 .phoras {
  -webkit-transform: translate(-93px,-15px);
  -moz-transform: translate(-93px,-15px);
  -o-transform: translate(-93px,-15px);
  -ms-transform: translate(-93px,-15px);
  transform: translate(-93px,-15px);
  text-align: right;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  color: #fcb322;
  font-size: 26px;
}

.content li.animate2 .box {
  -webkit-transform: translate(+100px,0);
  -moz-transform: translate(+100px,0);
  -o-transform: translate(+100px,0);
  -ms-transform: translate(+100px,0);
  transform: translate(+100px,0);
  
}

.content li.animate2 div.toolfirm:nth-child(1) {
  -webkit-transform: translate(+100px,0);
  -moz-transform: translate(+100px,0);
  -o-transform: translate(+100px,0);
  -ms-transform: translate(+100px,0);
  transform: translate(+100px,0);
}

 .content li.animate div.toolbox:nth-child(3) {
  -webkit-transform: translate(-100px,0);
  -moz-transform: translate(-100px,0);
  -o-transform: translate(-100px,0);
  -ms-transform: translate(-100px,0);
  transform: translate(-100px,0);
}

 .content li.animate div.toolbox:nth-child(4) {
  -webkit-transform: translate(-200px,0);
  -moz-transform: translate(-200px,0);
  -o-transform: translate(-200px,0);
  -ms-transform: translate(-200px,0);
  transform: translate(-200px,0);
}

 .content li.animate div.toolbox:nth-child(5) {
  -webkit-transform: translate(-300px,0);
  -moz-transform: translate(-300px,0);
  -o-transform: translate(-300px,0);
  -ms-transform: translate(-300px,0);
  transform: translate(-300px,0);
}


.swipe {
  position: relative;
  white-space: nowrap;
  letter-spacing: normal;
  transition: transform 0.5s;
  /*padding-top: 10px;*/
}