  .grid {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    top: 0px;
    left: 0;

    background-image:
      repeating-linear-gradient(rgba(253, 164, 0, 0.062) 0 1px, transparent 1px 100%),
      repeating-linear-gradient(90deg, rgba(253, 165, 0, 0.062) 0 1px, transparent 1px 100%);
    background-size: 18px 18px;
    z-index: 0;
  }
  
.basedraw {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
  }
  
  .tabledb {
    width: 470px;
    height: auto;
    /* background-color: rgb(255, 153, 0); */
    border-radius: 5px;
    position:relative;
    z-index: 1;
  }
  
  .titledb {
    font-size: 26px;
    color: #f7f7f7;
    display:inline;
    padding: 5px;
    pointer-events: none;
    padding-left: 25px;
  }

  .icodb {
    pointer-events: none;
    width: 20px;
    fill: #f7f7f7;
  }

  .campotitledb {
    display:block;
    background-color: rgb(255, 153, 0);
    border-radius: 3px;
    margin: 3px;
    padding: 3px;
    padding-left: 25px;
  }

  .tablenotedb {
    font-size: 12px;
    color: #f7f7f760;
    display:inline;
    padding: 5px;
    font-weight: 400;
    pointer-events: none;
    padding-left: 10px;
    Text-transform: uppercase;
    transition-duration: 500ms;
  }

  .campotitledb:hover {
    background-color: rgb(255, 174, 52);
  }

  .campotitledb:hover ~ .tablenotedb {
    transition-duration: 500ms;
    color: #f7f7f7d0;
  }

  .campodb {
    display:block;
    background-color: rgb(218, 218, 218);
    margin: 4px;
    border-radius: 3px;
    position: relative;   
    min-height: 60px; 
    z-index: 2;
  }

  .campodb:hover {
    background-color: rgb(185, 185, 185);
  }

  .campodb .ico_type_db{
    position: absolute;    
    background-color: rgb(255, 153, 0);
    color: #f7f7f7;
    font-size: 26px;
    left: 8px;
    width: 50px;
    text-align: center;
    pointer-events: none;
    z-index: 0;
  }

  .campodb .ico_key_db {
    transform: rotate(-123deg);
    position: absolute;    
    pointer-events: none;
    width: 40px;
    top: 15px;
    left: -8px;
    fill: #f7f7f7;
    z-index: 1;
  }

  .campodb .maxchr_type_db{
    position: absolute;    
    color:  rgb(26, 26, 26);
    font-size: 16px;
    font-weight: 600;
    bottom: 3px;
    left: 8px;
    width: 50px;
    text-align: center;
    pointer-events: none;
    z-index: 3;
  }

  .campodb .title_campo_db{
    position: absolute;    
    color: #313131;
    font-size: 24px;
    font-weight: 600;
    top: 3px;
    left: 70px;
    width: calc( 100% - 70px );
    text-align: left;
    pointer-events: none;
  }

  .campodb .coment_campo_db{
    position: absolute;    
    color: #8a8a8a;
    font-size: 16px;
    bottom: 3px;
    left: 70px;
    width: calc( 100% - 70px );
    text-align: left;
    pointer-events: none;
  }

  .campodb .boxin_db{
    position: absolute;    
    background-color: rgb(255, 153, 0);
    left: -4px;
    top: 5px;
    width: 4px;
    height: calc( 100% - 10px );
    border-radius: 3px;
    pointer-events: none;
  }

  .campodb .triain_db{
    position: absolute;    
    left: -1px;
    bottom: 5px;
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    border-left:15px solid rgb(255, 153, 0);
    pointer-events: none;
  }
  
  .campodb .inner {
    transform: rotate(45deg);
    background-color: rgb(70, 70, 70);
    width: 15px;
    height: 15px;
    top: 7px;
    left: -5px;
    position: relative;
    border-radius: 3px;
  }

  .campodb .inner2 {
    transform: rotate(45deg);
    background-color: rgb(255, 153, 0);
    width: 15px;
    height: 15px;
    top: 13px;
    left: 5px;
    position: relative;
    border-radius: 3px;
  }
  
  .campodb .outer {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 50px;
    overflow: hidden;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .informe {
    position: relative;    
    color:  rgb(207, 207, 207);
    font-size: 14px;
    font-weight: 600;
    top: 8px;
    left: 8px;
    width: 60px;
    text-align: center;
    pointer-events: none;
    z-index: 3;
  }
  
  /* s */

  