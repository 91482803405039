/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

.cardsl {
  position: relative;
  top: 20px;
  height: 350px;
  width: 100%;
  max-width: 850px;
  margin: auto;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 10px 50px rgba(0,0,0,0.3);
  z-index: 0;
}

.cardsl .inner_part {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 30px;
  height: 350px;
  position: absolute;
}

.cardsl #slideImg:checked ~ .inner_part {
  padding: 0;
  transition: .1s ease-in;
}

.cardsl .inner_part .img {
  height: 260px;
  width: 260px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: 0 10px 50px rgba(0,0,0,0.2);
}

.cardsl #slideImg:checked ~ .inner_part .img {
 height: 350px;
 width: 850px;
 z-index: 1; 
 transition: .3s .2s ease-in;
}

.cardsl .img img {
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  transition: .6s;
}

.cardsl #slide_1:checked ~ .inner_part .img_1,
.cardsl #slide_2:checked ~ .inner_part .img_2,
.cardsl #slide_3:checked ~ .inner_part .img_3 {
  opacity: 1;
  transition-delay: .2s;
}

.cardsl .cardslidercontent {
  padding: 0 20px 0 35px;
  width: 530px;
  margin-left: 50px;
  opacity: 0;
  transition: .6s; 
  box-sizing: border-box;
}

#slideImg:checked ~ .inner_part .cardslidercontent {
  display: none;
}

#slide_1:checked ~ .inner_part .content_1,
#slide_2:checked ~ .inner_part .content_2,
#slide_3:checked ~ .inner_part .content_3 {
  opacity: 1;
  margin-left: 0;
  z-index: 1; 
  transition-delay: .3s;
}

.cardslidercontent .cstitle {
  font-size: 30px;
  font-weight: 700;
  color: #0d0925;
  margin: 0 0 20px 0;
  position: absolute;
  top: 40px;
}

.cardslidercontent .textcs {
  font-size: 19px;
  color: #4e4a67;
  margin: 0 auto 30px auto;
  line-height: 1.5em;
  text-align: justify;
  padding-right: 30px;
  position: absolute;
  top: 100px;
}

.cardslider {
  position: absolute;
  bottom: 25px;
  left: 55%;
  transform: translateX(-50%);
  z-index: 2;
}

#slideImg:checked ~ .cardslider {
  display: none;
}

.cardslider .slide {
  position: relative;
  height: 10px;
  width: 50px;
  background: #d9d9d9;
  border-radius: 5px;
  display: inline-flex;
  margin: 0 3px;
  cursor: pointer;
}


.cardsl .cardslider .slide:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: -100%;
  background: #000000;;
  border-radius: 10px;
  transform: scaleX(0);
  transition: transform .6s;
  transform-origin: left;
}

#slide_1:checked ~ .cardslider .slide_1:before,
#slide_2:checked ~ .cardslider .slide_2:before,
#slide_3:checked ~ .cardslider .slide_3:before {
  transform: scaleX(1);
  width: 100%;
}

.cardsl input {
  display: none;
}

@media only screen and (max-width: 460px) {
    .cardsl .inner_part .img {
        display: none ;
    }
  
    .cardsl .cardslidercontent {
        padding: 0;
        width: 530px;
        margin-left: 0;
        opacity: 0;
        transition: .6s; 
      }
  
      .cardsl {
        position: relative;
        top: 30px;
        height: 300px;
        width: 90%;
        margin: auto;
        background: #ffffff;
        border-radius: 5px;
        margin-bottom: 40px;
      }

      .inner_part {
        top: 0px;
        width: 90%;
      }

      .cardslider {
        width: 55%;
      }

      
      .cardslidercontent .cstitle {
        top: 10px;
      }

      .cardslidercontent .textcs {
        top: 60px;
      }

  }