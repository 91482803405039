
 

form, ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  background-color: var(--borders-bg, #fcb322);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--btn-col, #fcb322);
}

input:focus + .slider {
  box-shadow: 0 0 0px var(--btn-col, #fcb322);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 14px;
}

.slider.round:before {
  border-radius: 50%;
}

#usercv .red_key:hover, #usercv .set_key:hover {
  color: var(--btn-col,#FCB322);
}

#usercv {
  left: 30px;
  top: 0px;
  width: calc( 100% - 30px);
  min-height: calc( 100% );
  position: absolute;
}

#usercv .info {
  left: 15px;
  top: 10px;
  width: auto;
  position: absolute;
  display: inline-block;
}

#usercv .infotext {
  text-align: left;
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: var(--fon-02,#FCB322);
  display: inline-block;
}

#usercv .box__label {
  position: relative;
  text-align: right;
  font-size: 16px;
  margin: 0;
  top: 5px;
  padding: 5px;
  color: var(--fon-02,#FCB322);
  display: inline-block;
}

#usercv .set_key {
  height: 20px;
  width: 20px;
  font-size: 16px;
  color: white;
  margin: 5px;
  text-align: center;
}

#usercv .set_link {
  right: 5px;
  top: 5px;
  height: 45px;
  position: absolute;
}

#usercv .box__heading {
  /*bottom: 10px;
  right: 15px;*/
  display: inline;
  left: auto;
  top: auto;
  width: auto;
  height: 25px;
  position: absolute;
}

#usercv .url_link {
  top: 30%;
  left: 20%;
  width: auto;
  position: absolute;
  display: inline-block;
}

.title {
  font-size: 1rem;
  color: white;
  text-align: right;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 5px;
  /* Rotate from top left corner (not default) */
  transform-origin: 0 0;
  transform: rotate(90deg);
}
.title_box {
  width: 300px;
  min-height: calc( 100% );
  position: absolute;
  border-left: 30px solid var(--btn-col,#FCB322);;
  padding: 0px;
}

.libox {
  left: 30px;
  top: 0px;
  width: calc( 100% - 30px);
  min-height: calc( 100% );
  position: relative;
}

.back-box-list {
    position: static;
    top: 0px;
    left: 0px;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 60px);
  }
    
    .hrow {
        top: 10px;
        left: 5%;
        width: 80%;
        position: relative;
    }
    
    table.hisTable {
      width: 100%;
      height: 100px;
      text-align: left;
      margin-bottom: 0rem;
    }
    
    table th {
        font-size: 14px;
        white-space: nowrap;
        margin: 0px;
        padding: 2px;
    }
    
    table td.subject {
        width: 10%;
    }
    
    table td.auto {
        width: 1%;
    }
    
    .back-box {
        margin: 0.5%;
      display: block;
      flex-direction:column;
      float: left;
      position: relative; /* fixed absolute relative static*/ 
      border-radius: 5px;
      color: white;
      top: 0px;
        left: 0px;
      height: 175px;
      /*background: url(img/urban.jpg);  #58585B */
      background: var(--column-bg,#FCB322);
    }
    
    .back-box.group {
        height: 35px;
    }
    
    .back-box.h2x {
        height: calc(350px + 1%);
    }
    
    .back-box.hax {
        height: auto;
    }
    
    
    /* HAY errores */
    
    
    
    .back-box.blue {
      background-color: rgba(34, 152, 241, 0.85);
      opacity: 0.85;
    }
    .back-box.green {
      background-color: rgba(102, 185, 46, 0.85); 
      opacity: 0.85;
    }
    .back-box.orange {
      background-color: rgba(218, 147, 44, 0.85);
      opacity: 0.85;
    }
    .back-box.red {
      background-color: #d65a4a; 
      opacity: 0.85;
    }
    
    .back-box.thema {
      background-color: rgba(20, 20, 20, var(--trans-gcolorh))
    }
    
    .back-box .zmdi {
      color: white;
      font-size: 48px;
      opacity: 0.3;
      position: absolute;
      right: 13px;
      top: 13px;
    }
    .back-box .stat {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 14px;
      margin-top: 15px;
      padding: 10px;
      text-transform: uppercase;
    }
    
    .back-box .title {
      display: inline-block;
      color: #191919;
      font-size: 18px;
      padding: 8px 0px 0px 10px;
      text-transform: uppercase;
    }
    
    .boxcub {
      left: 0px;
      top: 0px;
      padding: 0px;
      opacity: 1;
      position: relative;
      border: 0px solid red;
    }
    
    .boxcub .fon {
      display: inline-block;
      border: 0px solid green;
      margin: 3px 15px;
    }
    
    .boxcub .titles {
      text-transform: uppercase;
      color: white;
      font-size: 20px;
      left: 0px;
      top: 0px;
    }
    
    .boxcub .capt {
      text-transform: uppercase;
      color: white;
      font-size: 48px;
      left: 0px;
      top: 0px;
    }
    
    .boxcub .list {
      text-transform: uppercase;
      color: white;
      font-size: 36px;
      left: 0px;
      top: 0px;
    }
    
    .back-box .value {
      font-size: 28px;
      padding: 0 10px;
    }

    /*form styles  style="display: block;" width="650" height="280" */
    .back-box .icon {
      width: 65px;
      height: 65px;
      position: relative;
      border-radius: 20%;
      background-size: cover;
      margin: 15px;
    }
    
    .back-box .fname {
      left: 95px;
      top: 35px;
      position: absolute;
      font-size: 24px;
      padding: 10px 10px 0;
      text-transform: uppercase;
    }
    .back-box .lname {
      left: 95px;
      top: 65px;
      position: absolute;
      font-size: 24px;
      padding: 10px 10px 0;
      text-transform: uppercase;
    }
    
    .back-box .progress {
      position: relative;
      color: var(--text-color,rgb(236, 235, 233));
      background: var(--btn-col,#FCB322);
      height: 10px;
      margin: 10px;
    }
    
    canvas.vista{
        display: block;
        position: relative;
        /*width: 200vw; /*calc(100vw /2 - 60px);*/
        left: 15px;
        top: 25px;
        color: white;
        opacity: 1;
    }
    
    canvas.vista2{
        display: block;
        position: absolute;
        width: 300px;
        height: 300px;
        right: 50px;
        top: 25px;
        color: white;
        opacity: 1;
    }
    
    .gridhistory {
        padding: 3px;
    }

    .gridhistory ul{
      list-style-type: none;
  }
    
    .listelement {
        position: relative;
        z-index: 0;
        overflow: hidden;
        width: 100%;
        height: 45px;
        background-color: rgba(20, 20, 20, 0.568);
        margin: 3px;
        /* pointer-events: none; */
    }
    
    /* P@ */
    
    .listelement figure {
        position: relative;
        z-index: 1;
        /*display: inline-block;*/
        overflow: hidden;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        margin: 0px;
       /*-moz-transition: all 0.2s ease-in-out;
       -webkit-transition: all 0.2s ease-in-out;*/
       /*transition: all 0.2s ease-in-out;
       box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);*/
    }
    
    .listelement figure .pimg {
        z-index: 1;
        top: 5px;
        left: 115px;
        width: 32px;
        height: 32px;
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        border-radius: 100%;
        background-size: cover;
    }
    
    .listelement figure .flagimg {
        z-index: 1;
        top: 0px;
        left: 45%;
        position: absolute;
    }
    
  
    
    .listelement figure .checkboxs {
      z-index: 1;
      position: absolute;
      width: 32px;
      font-size: 24px;
        top: 10px;
        left: 0px;
    }
    
    .listelement figure .linenumero {
      z-index: 1;
      text-align: center;
      position: absolute;
      width: 40px;
      font-size: 24px;
      color: var(--list-text, #e4e4e4);
        top: 3px;
        left: 33px;
    }
    
    .listelement figure .lisdate {
      z-index: 1;
      text-align: center;
      position: absolute;
      width: 220px;
      font-size: 12px;
      color: var(--list-text, #e4e4e4);
        top: 24px;
        left: 120px;
    }
    
    .listelement figure .lineinout {
        z-index: 1;
      position: absolute;
      font-size: 20px;
      color: var(--list-text, #e4e4e4);
        top: 6px;
        left: 80px;
    }
    
    .listelement figure .linV {
        z-index: 1;
      position: absolute;
      font-size: 20px;
      color: #cc28be;
        top: 6px;
        left: 80px;
    }

    .listelement figure .linN {
      z-index: 1;
    position: absolute;
    font-size: 20px;
    color: #ff8800;
      top: 6px;
      left: 80px;
  }

    .listelement figure .linY {
      z-index: 1;
    position: absolute;
    font-size: 20px;
    color: #ffd61f;
      top: 6px;
      left: 80px;
  }

    .listelement figure .linB {
      z-index: 1;
    position: absolute;
    font-size: 20px;
    color: #b9b9b9;
      top: 6px;
      left: 80px;
  }

    .listelement figure .linR {
      z-index: 1;
    position: absolute;
    font-size: 20px;
    color: #ff0000;
      top: 6px;
      left: 80px;
  }
    
    .listelement figure .linG {
        z-index: 1;
      position: absolute;
      font-size: 20px;
      color: #00c93c;
        top: 6px;
        left: 80px;
    }
    
    .listelement figure .linB {
        z-index: 1;
      position: absolute;
      font-size: 20px;
      color: #008cff;
        top: 6px;
        left: 80px;
    }
    
    .listelement figure .lpais {
      z-index: 1;
      position: absolute;
      font-size: 14px;
      color: var(--list-text, #e4e4e4);
        top: 5px;
        left: 330px;
    }
    
    .listelement figure .lineip {
      z-index: 1;
      position: absolute;
      font-size: 12px;
      color: var(--list-text, #e4e4e4);
        top: 25px;
        left: 335px;
    }
    
    
    .listelement figure.userlist {
        border-radius: 0px;
        margin-top: 1px !important;
        pointer-events: none;
    }
    
    .listelement figure.userlist:hover {
        background: var(--list-sel, #ffc400);
        border-left: 5px solid var(--btn-col, #6698c8);
    }

    

    .lisinmarker:hover .btn_setings {
      height: 32px;
      width: 32px;
      top: 0px;
      right: 0px;
      font-size: 22px;
      margin: 5px;
      color: white;
      text-align: center;
      align-self: center;
      align-items: center;
      position: relative;
      border: solid 1px rgb(255, 255, 255);
      border-radius: 5px;
      display: inline-block;
      cursor: pointer;

  }
  
  .lisinmarker:hover .btn_setings:hover {
      color: rgb(255, 174, 0);
      border: solid 1px rgb(255, 174, 0);

  }

    
    .listelement figure.userlist .het {
        position: absolute;
        opacity: 1;
        z-index: 10;
        color: var(--list-text, #e4e4e4);
        text-shadow: 0px 0px #646464;
        display: inline-block;
        text-align: left;
        font-size: 18px;
        left: 160px;
        top: 0px;
        width: 94%;
        margin-top: 0px;
    
        /*-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: translate3d(0,0px,0);*/
        transform: translate3d(0px,0px,0);
    }
    
    .listelement figure.userlist:hover .het {
        position: absolute;
        opacity: 1;
        text-align: left;
        float: left;
        color: var(--list-text-sel, #e4e4e4);
        text-shadow: 0px 0px #e4e4e4;
        border-bottom: 0px solid #fff;
        top: 0px;
        font-size: 18px;
        /*-webkit-transform: translate3d(0px,0,0);*/
        transform: translate3d(0px,0px,0);
    }
    
    .listelement figure.userlist .hez {
        position: absolute;
        opacity: 1;
        z-index: 10;
        color: var(--list-text, #e4e4e4);
        display: inline-block;
        text-align: left;
        font-size: 16px;
        left: 500px;
        top: 5px;
        width: 58%;
        margin-top: 0px;
    
        /*-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
        transition: opacity 0.35s, transform 0.35s;
        -webkit-transform: translate3d(0,0px,0);*/
        transform: translate3d(0px,0px,0);
    }
    
    .listelement figure.userlist:hover .hez {
        position: absolute;
        opacity: 1;
        color: var(--list-text-sel, #e4e4e4);
        /*-webkit-transform: translate3d(0px,0,0);*/
        transform: translate3d(0px,0px,0);
    }
    
    .listelement figure.userlist .butbox {
        z-index: 10;
        position: absolute;
        display: block;
        text-align: right;
        float: left;
        height: 20px;
        width: 200px;
        top: 0px;
        left: 35%;
        z-index: 999;
    }
    
    .listelement figure.userlist .downicol {
        opacity: 0;
        z-index: 10;
        display: inline-block;
        margin: 5px;
        position: relative;
        height: 18px;
        width: 18px;
        font-size: 18px;
        /*-webkit-transition: opacity 0.25s, -webkit-transform 0.25s;
        transition: opacity 0.25s, transform 0.25s;
        -webkit-transform: translate3d(0px,0px,0);*/
        transform: translate3d(0,0,0);
    }
    
    .listelement figure.userlist:hover .downicol {
        opacity: 1;
        -webkit-transform: translate3d(0px,0px,0);
        transform: translate3d(0,0,0);
    }

    /* CHARTS */

    canvas.vista{
        display: block;
        position: relative;
        /*width: 200vw; /*calc(100vw /2 - 60px);*/
        left: 15px;
        top: 25px;
        color: white;
        opacity: 1;
    }
    
    canvas.vista2{
        display: block;
        position: absolute;
        width: 300px;
        height: 300px;
        right: 50px;
        top: 25px;
        color: white;
        opacity: 1;
    }

  .WM001hide{
    position: relative;
    visibility: hidden;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    right: 0px;
    top: -65px;
    transition: .3s; /*Скорость перехода состояния элемента*/
}

.WM001open{
    position: relative;
    visibility: visible;
    right: 0px;
    top: -65px;
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: .3s; /*Скорость перехода состояния элемента*/
    
}

  .CtrlModalWidg {
      position: absolute;
      top: 15px;
      right: 20px;
      color: white;
  }

  .ModalWidg {
    position: fixed;
    top: 10%;
    left: 10%;
    padding: 20px;
    background-color: rgba(0,0,0,0.9);
    color: white;
    height: 80%;
    width: 80%;
    z-index: 0;
    overflow-y: auto;

}

  #WM001 .ctrl_link {
    right: calc(10% );
    top: calc(5% + 35px);
    height: 45px;
    position:  fixed;
  }

  #WM001 .my-code{
    left: 15%;
    top: 20%;
    width: 256px;
    height: 256px;
    position: fixed;
    filter: invert(100%);
    mix-blend-mode: screen;
  }

  .downmodal {
    position: fixed;
    left: 10%;
    bottom: 20px;
    width: 88%;
  }
  
  @keyframes menu2in {
    0%   {left:300px; top:-65px;}
    100%  {left:0px; top:-65px;}
  }

  @keyframes menu2out {
    0%   {left:0px; top:-65px;}
    100%  {left:300px; top:-65px;}
  }

  /* sin error*/
    @media only screen and (max-width: 600px) {

      .back-box.s1x {
        width: 98.4%;
      }
      .back-box.s2x {
          width: 98.4%;
      }
      .back-box.s3x {
          width: 98.4%;
      }
      .back-box.s4x {
          width: 98.4%;
      }

      .cvback-box .url_link {
        left: auto;
        right: 10px;
        top: 5px;
        width: 180px;
        height: 40px;
        position: absolute;
    }

    .cvback-box .red_key {
      height: 35px;
      width: 35px;
      font-size: 30px;
      color: white;
      margin: 5px;
      text-align: center;
    }

      .cvback-box .lname {
        left: 120px;
        top: 190px;
        font-size: 14px;
      }

      .cvback-box .Qrstatus{
        visibility: hidden;
      }

      .cvback-box .qr-code{
        visibility: hidden;
      }

      .cvback-box .fname {
        left: 110px;
        top: 150px;
        font-size: 28px;
      }

      .cvback-box .panel {
        float: left;
        position: absolute; /* fixed absolute relative static*/ 
        top: 1px;
        left: 0px;  
        height: 275px;
        width: 64px;
      }

      .cvback-box .iconsh {
        left: calc(8% - 5px);
        top: 150px;
        width: 85px;
        height: 85px;
        position: absolute;
        border-radius: 100%;
        background: var(--fon-01);
        opacity: 0.3;
      }

      .cvback-box .icon {
        left: 8%;
        top: 155px;
        width: 75px;
        height: 75px;
        position: absolute;
        border-radius: 100%;
        background-size: cover;
        margin: 0px;
        opacity: 1;
      }

      .cvback-box input.iconAva {
        position: relative;
        left: 0;
        top: 0;
        bottom: 0;
        font-size: 1px;
        opacity: 0;
        margin: 0;
        padding: 0;
        width: 75px;
        height: 75px;
        cursor: pointer;
      }

      .ModalWidg {
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.9);
        height: 100%;
        padding-top: 50px;
        width: 100%;
        z-index: 0;
    }

    #WM001 .my-code{
      left: 10%;
      top: 20%;
      width: 80%;
      height: auto;
      filter: invert(100%);
    }

    #WM001 .ctrl_link {
      visibility:hidden;
    }

    .listelement {
      width: 97%;
      height: 95px;
      margin: 0px;
      background-color: rgba(20, 20, 20, 0.61);
      margin: 5px;
      border-radius: 10px;

  }

  .listelement figure.userlist .hez {
    left: 3%;
    top: 50px;
    width: 95%;
}

.listelement figure .lpais {
    top: 0px;
    right: 25px;
    width: 10px;
    left: auto;
}

.listelement figure .lineip {
    top: 20px;
    left: 170px;
}

.listelement figure .lisdate {
    top: 35px;
    left: 170px;
    text-align: left;
}



    }
    
    @media (min-width: 415px) {

      .back-box.s1x {
        width: 48.7%;
      }
      .back-box.s2x {
          width: 98.4%;
      }
      .back-box.s3x {
          width: 98.4%;
      }
      .back-box.s4x {
          width: 98.4%;
      }

    }


  @media (min-width: 1280px)  {

    .back-box.s1x {
      width: 31.65%;
    }
    .back-box.s2x {
        width: 62.7%;
    }
    .back-box.s3x {
        width: 97%;
    }
    .back-box.s4x {
        width: 97%;
    }

  }

    