.appbar { 
    position: fixed;
    display: inline-block;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,0.8);
    height: 50px;
    width: 100%;
}

.app_user_card{
    position: relative;
    width: 100%;
    text-align: center;
}

.AppMenuBtm {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    height: auto; 
}

.btnboxmenu{ 
    background: var(--btn-col);
    /*--switch: calc((var(--btn-col) - var(--text-color)) * -100);*/
    /*color: hsl(0, 0%, var(--switch));*/
    font-size: 20px;
    text-align: center;
    text-transform:uppercase;
    outline: none;
    width: 90%;
    height: 50px;
    border: 0;
	padding: 10px 20px;
    margin-top:5px;
    margin-left: 15px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

.cardEvent {
    width: 170px;
    height: 110px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 15px 15px #f7f7f7;
    padding-left: 23px;
    padding-top: 20px;
    box-sizing: border-box;
  }

  .btnboxcardadd{ 
    color:#fff;
    background:rgba(0,0,0,0.9);
      outline: none;
      width: 170px;
      height: 110px;
      border: 0;
    padding:10px 20px;
    text-transform:uppercase;
      text-align: center;
      margin-top:5px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom:5px;
    border-radius:3px;
    cursor:pointer;
      position:relative;
      opacity: 1;
      box-sizing: border-box;
  }

.btnboxcard{ 
	color:#fff;
	background:linear-gradient(60deg, rgb(192, 130, 15) 0%, rgb(255, 166, 0) 63%, rgb(199, 121, 31) 100%);
    outline: none;
    width: 170px;
    height: 110px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    text-align: center;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
    box-sizing: border-box;
}

.btnboxcard:hover {
    background:linear-gradient(60deg, rgb(255, 166, 0) 0%, rgb(255, 185, 56) 63%, rgb(255, 136, 0) 100%);
  }

.btn{ 
	color:#fff;
	background-color:#FCB322;
    outline: none;
    width: 100%;
    height: 35px;
    border: 0;
	padding:10px 20px;
	text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
	border-radius:3px;
	cursor:pointer;
    position:relative;
    opacity: 1;
}

input, textarea {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 95%;
    box-sizing: border-box;
    color: var(--column-bg,#2C3E50);
    font-size: 16px;
}

.textcampo {
    position: relative;
    margin: 5px;
    border-radius: 3px;
    margin-bottom: 10px;
    width: 95%;
    color: var(--column-bg,#2C3E50);
    font-size: 16px;
}

.react-datepicker-wrapper{
    position: relative;
    width: 100%;
}
.app_user_card .userc_ava{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 64px;
    border-radius: 20%;
    filter: brightness(90%);
    /*box-shadow: 0px 0px 7px rgb(255, 255, 255);*/
}

.app_user_card .userc_name {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 100px;
    margin:auto;
  }

  .app_user_card .userc_status {
    color: var(--btn-col,#FCB322);
    font-size: 14px;
    position: absolute;
    top: 35px;
    left: 100px;
    margin:auto;
  }

  .app_user_rank {
    background-color: rgba(10, 10, 10, 1);
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0px;
    top: 100px;
    
  }

  .app_user_rank .userc_rank{
    color: var(--text-color,#FCB322);
    background-color: rgba(25, 25, 25, 1);
    font-size: 25px;
    position: initial;
    text-align: center;
    list-style-type: none;
    width: 80px;
    height: 50px;
    left: 100px;
    margin:8px;
    padding: 13px 0;
    display: inline-block;
  }
  .app_user_rank .userc_rank_h1{
    color: var(--text-color,#FCB322);
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 80px;
    left: 20px;
    top: 50px;
    border: inset 0px red;
    margin:8px;

  }

  .app_user_rank .userc_rank_h2{
    color: var(--text-color,#FCB322);
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 80px;
    left: 115px;
    top: 50px;
    border: inset 0px red;
    margin:8px;

  }

  .app_user_rank .userc_rank_h3{
    color: var(--text-color,#FCB322);
    font-size: 14px;
    position: absolute;
    text-align: center;
    width: 80px;
    left: 210px;
    top: 50px;
    border: inset 0px red;
    margin:8px;

  }

  
  .UserMenuBtm {
    position: absolute;
    top: 220px;
    left: 0px;
    width: 100%;
    height: auto;
}

.menuhide{
    position: relative;
    visibility: hidden;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    left: 0px;
    top: -65px;
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuout;
    animation-duration: .3s;
}

.menuopen{
    position: relative;
    visibility: visible;
    left: 0px;
    top: -65px;
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menuin;
    animation-duration: .3s;
}

.UserMenuBox {
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: rgba(0,0,0,0.9);
    height: 100vh;
    width: 325px;
    z-index: 9999;
    filter: blur(0px);
}

.SadminMenuBox {
    position: absolute;
    top: 50px;
    right: 0px;
    background-color: rgba(0,0,0,0.9);
    height: 100vh;
    width: 325px;
    filter: blur(0px);
    z-index: 9999;
}

.menu2hide{
    position: relative;
    visibility: hidden;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    right: 0px;
    top: -65px;
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menu2out;
    animation-duration: .3s;
}

.menu2open{
    position: relative;
    visibility: visible;
    right: 0px;
    top: -65px;
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: .3s; /*Скорость перехода состояния элемента*/
    animation-name: menu2in;
    animation-duration: .3s;
}

@keyframes menu2in {
    0%   {left:300px; top:-65px;}
    100%  {left:0px; top:-65px;}
  }

  @keyframes menu2out {
    0%   {left:0px; top:-65px;}
    100%  {left:300px; top:-65px;}
  }


@keyframes menuin {
    0%   {left:-300px; top:-65px;}
    100%  {left:0px; top:-65px;}
  }

  @keyframes menuout {
    0%   {left:0px; top:-65px;}
    100%  {left:-300px; top:-65px;}
  }

.h1welcom{
    color: white;
    width: 90%;
}
.AppMenuBox {
    position: absolute;
    top: 50px;
    left: 0px;
    background-color: rgba(0,0,0,0.9);
    height: 100vh;
    width: 325px;
    z-index: 2;
    filter: blur(0px);
}

.appcard {
      position: relative;
      top: 40px;
      width: 100%;
      max-width: 850px;
      margin: auto;
      border-radius: 25px;
}

.sadmin {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    margin: 5px 0px 0px 0px;
    right: 55px;
    width: 40px;
    height: auto;
    border-radius: 5px;
    z-index: 999;
}

.appava {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    margin: 5px 0px 0px 0px;
    right: 5px;
    width: 40px;
    height: auto;
    border-radius: 5px;
    z-index: 999;
    
}

.applogo {
    position: absolute;
    display: inline-block;
    margin: 13px 0px 0px 0px;
    width: 20px;
    height: auto;
}

.applogotext {
    position: absolute;
    display: inline-block;
    margin: 10px 0px 0px 0px;
    left: 90px;
    font-size: 24px;
    width: auto;
    color: white;
    height: auto;
    text-transform:uppercase
}

/* Icon X */
#nav-icon3 {
    width: 30px;
    height: 30px;
    position: relative;
    display:inline-block;
    margin: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    cursor: pointer;
  }

  #nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #ffffff;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
/* Icon X */

#nav-icon3 span:nth-child(1) {
top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
top: 10px;
}

#nav-icon3 span:nth-child(4) {
top: 20px;
}

#nav-icon3.open span:nth-child(1) {
top: 10px;
width: 0%;
left: 50%;
}

#nav-icon3.open span:nth-child(2) {
-webkit-transform: rotate(45deg);
-moz-transform: rotate(45deg);
-o-transform: rotate(45deg);
transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
-webkit-transform: rotate(-45deg);
-moz-transform: rotate(-45deg);
-o-transform: rotate(-45deg);
transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
top: 10px;
width: 0%;
left: 50%;
}
/* end Icon X */

@media only screen and (max-width: 900px) {

  .btnboxcard{ 
      width: 22.5%;
  }
}


@media only screen and (max-width: 640px) {

    .btnboxcard{ 
        color:#fff;
        background-color:#FCB322;
        outline: none;
        width: 21.5%;
        height: 100px;
        border: 0;
        padding:10px 20px;
        text-transform:uppercase;
        margin-top:5px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom:5px;
        border-radius:3px;
        text-align: center;
        cursor:pointer;
        position:relative;
        opacity: 1;
    }

    .applogotext {
        position: absolute;
        display: inline-block;
        margin: 10px 0px 0px 0px;
        left: 90px;
        font-size: 24px;
        width: auto;
        color: white;
        height: auto;
    }

}

@media only screen and (max-width: 460px) {
  .btnboxcard{ 
      width: 45%;
  }


}



@media only screen and (max-width: 320px) {

  .btnboxcard{ 
    color:#fff;
    background-color:#FCB322;
    outline: none;
    width: 93%;
    height: 100px;
    border: 0;
    padding:10px 20px;
    text-transform:uppercase;
    margin-top:5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom:5px;
    border-radius:3px;
    text-align: center;
    cursor:pointer;
    position:relative;
    opacity: 1;
}

.applogotext {
    position: absolute;
    display: inline-block;
    margin: 14px 0px 0px 0px;
    left: 90px;
    font-size: 24px;
    width: 200px;
    color: white;
    height: auto;
}
}



.breadcrumb {
    display: flex;
    position: absolute;
    top: -17px;
    left: 75px;
    
  }
  .breadcrumb li {
    padding: 0 1.5rem;
    display: flex;
    position: relative;
    
  }

  .breadcrumb li.active,
  .breadcrumb li.active a:visited {
    color: #f39321;
  }
  .breadcrumb li.active a:hover, .breadcrumb li.active a:active,
  .breadcrumb li.active a:visited:hover,
  .breadcrumb li.active a:visited:active {
    color: #f6ab51;
  }
  .breadcrumb li a,
  .breadcrumb li a:visited {
    display: block;
    padding: 1rem 0;
    text-decoration: none;
    color: #c9d1d9;
  }
  .breadcrumb li a:hover, .breadcrumb li a:active,
  .breadcrumb li a:visited:hover,
  .breadcrumb li a:visited:active {
    color: #e7ebee;
  }
  .breadcrumb.breadcrumb--classic li:not(:last-of-type)::before {
    content: "/";
    display: block;
    position: absolute;
    font-size: 1.75rem;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    color: #6f859a;
  }

  
  