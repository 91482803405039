

  .modal {
      overflow: hidden;
      position: relative;
  }
  .modal-dialog {
      margin-right: 0;
      margin-left: 0;
  }
  .modal-header {
    height:10px;
    padding: 20px;
    background-color:#18456b;
    color:white;
    color: #141414e1;
    width: 800px;
  }

  .modal-title {
    margin-top:-5px;
    font-size:16px;
    pointer-events: none;
    color:rgb(221, 177, 95);

  }

  .modal-header .close {
    margin-top:-5px;
    color:#fff;
    right: 25px;
    position: absolute;
  }
  .modal-body {
    color:#888;
    padding: 20px;
  }
  
  .WMhide{
    position: relative;
    visibility: hidden;
    opacity:0; /*Элемент полностью прозрачный (невидимый)*/
    right: 0px;
    top: -65px;
    transition: .3s; /*Скорость перехода состояния элемента*/
}

.WMopen{
    position: relative;
    visibility: visible;
    right: 0px;
    top: -65px;
    opacity:1; /*Элемент полностью прозрачный (невидимый)*/
    transition: .3s; /*Скорость перехода состояния элемента*/
}

.modal .modal-footer {
  position: absolute;
  text-align:right;
  border-top:1px solid #e5e5e5;
  bottom: 25px;
  width: 800px;
}

.modal .modal-footer .btn {
  display: inline-block;
  padding: 6px 32px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  width: auto;
}

.modal .btn-primary {
  color: rgb(255, 255, 255);
  background-color: rgb(4, 116, 19);
  border-color: rgb(102, 102, 102);
}

.modal .btn-default {
  color: #333;
  background-color: rgb(68, 68, 68);
  border-color: rgb(102, 102, 102);
}

.conteiners {
  /*display: flex;*/
  /* pointer-events: none; */
  padding: 0 10px;
  flex-wrap:wrap;
  overflow-y: scroll;
  overflow: hidden;
  /*overflow-y: scroll;*/
  width: calc( 100% - 380px);
  height: calc( 100% - 50px);
  position: absolute;
  top: 65px;
  left: 350px;
}