:root {
  --CalJL-fon: #303e4d; /* barmenu */  
  --CalJL-text: #fcb322; /* menu */
  --CalJL-fscolor: #14304e; /* lista-color */ 
  --CalJL-ficolor: #4e1414; /* lista-color */ 
  --CalJL-pucolor: #4e4d14; /* lista-color */ 
  --CalJL-vacolor: #03721b; /* lista-color */
  --CalJL-calender-day: #303e4d4d; /* barmenu */  

}

.notwork {
  display: none;
}

.JorCalendar {
  width: 99%;
  height: 300px;
  border: solid 0px red;
  position: relative;
  top: 00px;
  margin: 5px;
}

.CalendarMes{
  width: 355px;
  height: 300px;
  border: solid 0px red;
  position: relative;
  top: 00px;
  margin: 5px;
}

.colFail {
  Color: rgba(200, 10, 10, 0.8);
}
.colWait {
  Color: rgba(200, 200, 10, 0.8);
}
.colSave {
  Color: rgba(10, 200, 10, 0.8);
}

.wdayselect{
  background-color: rgba(252, 179, 34, 0.5);
}

.wday{
  width: 98%;
  height: 39px;
  position: relative;
  float:left;
  margin: 2px;
  border-radius: 3px;
}

.wday:hover{
  background-color:rgb(252, 179, 34);
  color: var(--CalJL-fon);
}

.wday .cday{
  background-color: rgba(0, 0, 0, 0);
  width: 40px;
  height: 20px;
  left: 15px;
  text-align: center;
  position: relative;
  float:left;
  font-size: 28px;
}
.wday .dday{
  background-color: rgba(0, 0, 0, 0);
  left: 3px;
  top: 1px;
  position: absolute;
  font-size: 10px;
  color: #929292;
}
.wday .lugar{
  left: 70px;
  bottom: 2px;
  position: absolute;
  font-size: 12px;
}

.wday .cliente{
  left: 70px;
  top: 3px;
  position: absolute;
  font-size: 16px;
}

.wday .sday{
  background-color: rgba(0, 0, 0, 0);
  left: 3px;
  bottom: 4px;
  position: absolute;
  font-size: 14px;
}

.wday .choras{
  right: 5px;
  top: 1px;
  position: absolute;
  font-size: 10px;
}

.wday .horas{
  right: 10px;
  top: 10px;
  position: absolute;
  font-size: 20px;
}

.wday .vertical-line{
  width: 1px; /* Line width */
  background-color: black; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left; 
  left: 60px;
  position: absolute;

  /* Causes the line to float to left of content. 
    You can instead use position:absolute or display:inline-block
    if this fits better with your design */
}

.wday .checkico{
  right: 50px;
  top: 0px;
  position: absolute;
  font-size: 28px;
}

.calendstatus{
  left: 3px;
  bottom: 3px;
  position: absolute;
  font-size: 12px;
  pointer-events: none;
}

.bwait{
  border: solid 1px rgba(200, 200, 10, 0.8);;
}

.DayLJC{
  width: 40px;
  height: 40px;
  text-align: center;
  position: relative;
  float:left;
  margin: 2px;
}

.DaySelect{
  background-color: rgba(252, 179, 34, 0.5);
  border: solid 0px var(--CalJL-text);
  width: 40px;
  height: 40px;
  text-align: center;
  position: relative;
  float:left;
  margin: 2px;

}

.DayLJC:hover{
  background-color:rgb(119, 119, 119);
  border: solid 1px var(--CalJL-text);
  margin: 0px;
  padding: 1px;
}

.ClPCapt {
  font-size: 11px;
  padding: 5px;
  margin: 0px;
  color: var(--CalJL-text);
  display: inline;
}

.ClPbox{
  width: 8px;
  height: 8px;
  margin: 2px;
  display: inline;
}

.DayCaption{
  background-color: rgba(0, 0, 0, 0);
  width: 40px;
  height: 20px;
  text-align: center;
  position: relative;
  float:left;
  margin: 2px;
  font-size: 14px;
}

.WeekCaption{
  background-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  float:left;
  margin: 2px;
  font-size: 14px;
  margin-top: 15px;
}

.SpaceCaption{
  background-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  float:left;
  margin: 2px;
  font-size: 14px;
}


.CaptionDayJC{
  font-size: 10px;
  color: rgb(192, 192, 192);
  text-align: left;
  margin: 0px;
  position: absolute;
  top: 0px;
  left: 3px;
  pointer-events: none;
}

.DateDayJC{
  font-size: 20px;
  text-align: center;
  position: absolute;
  color: var(--CalJL-text);
  top: 6%;
  left: 20%;
  margin: 1px;
  width: 80%;
  pointer-events: none;
}

.colFS {
  background-color: var(--CalJL-fscolor);
}
.colFi {
  background-color: var(--CalJL-ficolor);
}
.colPu {
  background-color: var(--CalJL-pucolor);
}

.colVa {
  background-color: var(--CalJL-vacolor);
}

.colfirmado {
  background-color: rgba(252, 179, 34, 0.5);
  border: solid 2px var(--CalJL-text);
  height: 36px;
  width: 36px;
  color: black;
}

.colfirmsem {
  background-color: rgba(9, 88, 9, 0.712);
  height: 39px;
  width: 98%;
  margin: 2px;
}

.colTr {
  background-color:rgba(0, 0, 0, 0.33);
}

.callogoimg {
  position: absolute;
  height: 64px;
  width: 64px;
  left: 16px;
  top: 0px;
}

.LugarDayJC{
  font-size: 10px;
  text-align: center;
  position: absolute;
  top: 1px;
  left: 3px;
  margin: 1px;
  color: rgb(100, 100, 100);
  pointer-events: none;
}

.HorasDayJC{
  font-size: 12px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 3px;
  margin: 1px;
  color: rgb(192, 192, 192);
  pointer-events: none;
}


.chatrlegbox {
  width: 16px;
  height: 16px;
}

.chartmeshoras {
  position: relative;
  width: 150px;
  height: 150px;
  padding: 10px;
  top: 0px;
  left: 30px;
  z-index: 0;
}

.Sem1JC{
  display: block;
}

.GrP1{
  position: absolute;
  left: 0px;
  margin: 5px;
  padding: 5px;
  top: 75px;
  width: 45%;
  border: solid 0px rgb(189, 189, 189);
}

.GrP2{
  position: absolute;
  right: 0px;
  margin: 5px;
  padding: 5px;
  top: 75px;
  width: 45%;
  border: solid 0px rgb(189, 189, 189);
}

.GrP3{
  position: absolute;
  left: 0;
  margin: 5px;
  padding: 5px;
  top: 185px;
  width: calc(100% - 10px);
  border: solid 0px rgb(189, 189, 189);
}

.GrP4{
  position: absolute;
  left: 10%;
  margin: 5px;
  padding: 5px;
  top: 70px;
  width: calc(71% - 10px);
  border: solid 0px rgb(189, 189, 189);
}

.GrP3line {
  top: 300px;
  left: 10px;
  width: 90%;
  height: 35px;
  position: absolute;
}

.TextBoxMax{
  width: 100%;
  height: 100%;
}

.GrPCapt{
  text-align: right;
  font-size: 12px;
  padding: 5px;
  margin: 0px;
  color: var(--CalJL-text);
}



.GrStotal{
  position: absolute;
  text-align: right;
  font-size: 20px;
  padding: 5px;
  margin: 0px;
  bottom: -35px;
  right: 5px;
  color: white;
}

.sulitew {
  top: 0px;
  width: auto;
  height: 35px;
  font-size: 12px;
  padding-left: 15px;
  margin: 3px;
}

@media (min-width: 600px)  {

  .DayLJC{
    width: 11.5%;
    height: 50px;
  }

  .DayCaption{
    width: 11.5%;
    height: 23px;
  }

  .CalendarMes{
    width: 95%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: 11.5%;
    height: 50px;
  }
}

@media (min-width: 800px)  {

  .DayLJC{
    width: 12%;
    height: 50px;
  }

  .DayCaption{
    width: 12%;
    height: 23px;
  }

  .CalendarMes{
    width: 100%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: 12%;
    height: 50px;
  }
}

@media (min-width: 1024px)  {

  .DayLJC{
    width: calc(3px + 12%);
    height: 50px;
  }

  .DayCaption{
    width: calc(3px + 12%);
    height: 23px;
  }

  .CalendarMes{
    width: 100%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: calc(3px + 12%);
    height: 50px;
  }
}

@media (min-width: 1280px)  {

  .DayLJC{
    width: calc(3px + 11%);
    height: 50px;
  }

  .DayCaption{
    width: calc(3px + 11%);
    height: 23px;
  }

  .CalendarMes{
    width: 100%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: calc(3px + 11%);
    height: 50px;
  }

  .SpaceCaption {
    background-color: rgba(0, 0, 0, 0);
    width: 23px;
    height: 23px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 14px;
}

.WeekCaption {
  background-color: rgba(0, 0, 0, 0);
  width: 20px;
  height: 23px;
  text-align: center;
  position: relative;
  float: left;
  margin: 2px;
  font-size: 14px;
  margin-top: 15px;
}

}


@media (min-width: 1440px)  {

  .DayLJC{
    width: calc(3px + 11%);
    height: 50px;
  }

  .DayCaption{
    width: calc(3px + 11%);
    height: 23px;
  }

  .CalendarMes{
    width: 100%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: calc(3px + 11%);
    height: 50px;
  }

  .SpaceCaption {
    background-color: rgba(0, 0, 0, 0);
    width: 23px;
    height: 23px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 14px;
}

.WeekCaption {
  background-color: rgba(0, 0, 0, 0);
  width: 25px;
  height: 23px;
  text-align: center;
  position: relative;
  float: left;
  margin: 2px;
  font-size: 14px;
  margin-top: 15px;
}

}


@media (min-width: 1551px)  {

  .DayLJC{
    width: calc(3px + 12%);
    height: 50px;
  }

  .DayCaption{
    width: calc(3px + 12%);
    height: 23px;
  }

  .CalendarMes{
    width: 100%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: calc(3px + 12%);
    height: 50px;
  }

  .SpaceCaption {
    background-color: rgba(0, 0, 0, 0);
    width: 23px;
    height: 23px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 14px;
}

.WeekCaption {
  background-color: rgba(0, 0, 0, 0);
  width: 25px;
  height: 23px;
  text-align: center;
  position: relative;
  float: left;
  margin: 2px;
  font-size: 14px;
  margin-top: 15px;
}

}

@media (min-width: 2354px)  {

  .DayLJC{
    width: calc(3px + 12.5%);
    height: 50px;
  }

  .DayCaption{
    width: calc(3px + 12.5%);
    height: 23px;
  }

  .CalendarMes{
    width: 100%;
  }

  .DaySelect{
    border: solid 0px var(--CalJL-text);
    width: calc(3px + 12.5%);
    height: 50px;
  }

  .SpaceCaption {
    background-color: rgba(0, 0, 0, 0);
    width: 23px;
    height: 23px;
    text-align: center;
    position: relative;
    float: left;
    margin: 2px;
    font-size: 14px;
}

.WeekCaption {
  background-color: rgba(0, 0, 0, 0);
  width: 25px;
  height: 23px;
  text-align: center;
  position: relative;
  float: left;
  margin: 2px;
  font-size: 14px;
  margin-top: 15px;
}

}